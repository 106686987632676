// source: mappa.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.mappa.CandidatePb', null, global);
goog.exportSymbol('proto.mappa.ComunePb', null, global);
goog.exportSymbol('proto.mappa.ComuniPb', null, global);
goog.exportSymbol('proto.mappa.InternoPb', null, global);
goog.exportSymbol('proto.mappa.ListaPb', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mappa.ComuniPb = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.mappa.ComuniPb.repeatedFields_,
    null
  );
};
goog.inherits(proto.mappa.ComuniPb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mappa.ComuniPb.displayName = 'proto.mappa.ComuniPb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mappa.ComunePb = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.mappa.ComunePb.repeatedFields_,
    null
  );
};
goog.inherits(proto.mappa.ComunePb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mappa.ComunePb.displayName = 'proto.mappa.ComunePb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mappa.InternoPb = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mappa.InternoPb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mappa.InternoPb.displayName = 'proto.mappa.InternoPb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mappa.CandidatePb = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.mappa.CandidatePb.repeatedFields_,
    null
  );
};
goog.inherits(proto.mappa.CandidatePb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mappa.CandidatePb.displayName = 'proto.mappa.CandidatePb';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mappa.ListaPb = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mappa.ListaPb, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mappa.ListaPb.displayName = 'proto.mappa.ListaPb';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mappa.ComuniPb.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mappa.ComuniPb.prototype.toObject = function (opt_includeInstance) {
    return proto.mappa.ComuniPb.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mappa.ComuniPb} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mappa.ComuniPb.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        comuniList: jspb.Message.toObjectList(
          msg.getComuniList(),
          proto.mappa.ComunePb.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mappa.ComuniPb}
 */
proto.mappa.ComuniPb.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mappa.ComuniPb();
  return proto.mappa.ComuniPb.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mappa.ComuniPb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mappa.ComuniPb}
 */
proto.mappa.ComuniPb.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.mappa.ComunePb();
        reader.readMessage(
          value,
          proto.mappa.ComunePb.deserializeBinaryFromReader
        );
        msg.addComuni(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mappa.ComuniPb.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.mappa.ComuniPb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mappa.ComuniPb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mappa.ComuniPb.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getComuniList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mappa.ComunePb.serializeBinaryToWriter
    );
  }
};

/**
 * repeated ComunePb comuni = 1;
 * @return {!Array<!proto.mappa.ComunePb>}
 */
proto.mappa.ComuniPb.prototype.getComuniList = function () {
  return /** @type{!Array<!proto.mappa.ComunePb>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mappa.ComunePb, 1)
  );
};

/**
 * @param {!Array<!proto.mappa.ComunePb>} value
 * @return {!proto.mappa.ComuniPb} returns this
 */
proto.mappa.ComuniPb.prototype.setComuniList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.mappa.ComunePb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mappa.ComunePb}
 */
proto.mappa.ComuniPb.prototype.addComuni = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.mappa.ComunePb,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mappa.ComuniPb} returns this
 */
proto.mappa.ComuniPb.prototype.clearComuniList = function () {
  return this.setComuniList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mappa.ComunePb.repeatedFields_ = [2, 3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mappa.ComunePb.prototype.toObject = function (opt_includeInstance) {
    return proto.mappa.ComunePb.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mappa.ComunePb} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mappa.ComunePb.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        interno:
          (f = msg.getInterno()) &&
          proto.mappa.InternoPb.toObject(includeInstance, f),
        candidatiList: jspb.Message.toObjectList(
          msg.getCandidatiList(),
          proto.mappa.CandidatePb.toObject,
          includeInstance
        ),
        listeList: jspb.Message.toObjectList(
          msg.getListeList(),
          proto.mappa.ListaPb.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mappa.ComunePb}
 */
proto.mappa.ComunePb.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mappa.ComunePb();
  return proto.mappa.ComunePb.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mappa.ComunePb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mappa.ComunePb}
 */
proto.mappa.ComunePb.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.mappa.InternoPb();
        reader.readMessage(
          value,
          proto.mappa.InternoPb.deserializeBinaryFromReader
        );
        msg.setInterno(value);
        break;
      case 2:
        var value = new proto.mappa.CandidatePb();
        reader.readMessage(
          value,
          proto.mappa.CandidatePb.deserializeBinaryFromReader
        );
        msg.addCandidati(value);
        break;
      case 3:
        var value = new proto.mappa.ListaPb();
        reader.readMessage(
          value,
          proto.mappa.ListaPb.deserializeBinaryFromReader
        );
        msg.addListe(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mappa.ComunePb.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.mappa.ComunePb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mappa.ComunePb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mappa.ComunePb.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getInterno();
  if (f != null) {
    writer.writeMessage(1, f, proto.mappa.InternoPb.serializeBinaryToWriter);
  }
  f = message.getCandidatiList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mappa.CandidatePb.serializeBinaryToWriter
    );
  }
  f = message.getListeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mappa.ListaPb.serializeBinaryToWriter
    );
  }
};

/**
 * optional InternoPb interno = 1;
 * @return {?proto.mappa.InternoPb}
 */
proto.mappa.ComunePb.prototype.getInterno = function () {
  return /** @type{?proto.mappa.InternoPb} */ (
    jspb.Message.getWrapperField(this, proto.mappa.InternoPb, 1)
  );
};

/**
 * @param {?proto.mappa.InternoPb|undefined} value
 * @return {!proto.mappa.ComunePb} returns this
 */
proto.mappa.ComunePb.prototype.setInterno = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.mappa.ComunePb} returns this
 */
proto.mappa.ComunePb.prototype.clearInterno = function () {
  return this.setInterno(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mappa.ComunePb.prototype.hasInterno = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated CandidatePb candidati = 2;
 * @return {!Array<!proto.mappa.CandidatePb>}
 */
proto.mappa.ComunePb.prototype.getCandidatiList = function () {
  return /** @type{!Array<!proto.mappa.CandidatePb>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mappa.CandidatePb, 2)
  );
};

/**
 * @param {!Array<!proto.mappa.CandidatePb>} value
 * @return {!proto.mappa.ComunePb} returns this
 */
proto.mappa.ComunePb.prototype.setCandidatiList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.mappa.CandidatePb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mappa.CandidatePb}
 */
proto.mappa.ComunePb.prototype.addCandidati = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.mappa.CandidatePb,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mappa.ComunePb} returns this
 */
proto.mappa.ComunePb.prototype.clearCandidatiList = function () {
  return this.setCandidatiList([]);
};

/**
 * repeated ListaPb liste = 3;
 * @return {!Array<!proto.mappa.ListaPb>}
 */
proto.mappa.ComunePb.prototype.getListeList = function () {
  return /** @type{!Array<!proto.mappa.ListaPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mappa.ListaPb, 3)
  );
};

/**
 * @param {!Array<!proto.mappa.ListaPb>} value
 * @return {!proto.mappa.ComunePb} returns this
 */
proto.mappa.ComunePb.prototype.setListeList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.mappa.ListaPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mappa.ListaPb}
 */
proto.mappa.ComunePb.prototype.addListe = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.mappa.ListaPb,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mappa.ComunePb} returns this
 */
proto.mappa.ComunePb.prototype.clearListeList = function () {
  return this.setListeList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mappa.InternoPb.prototype.toObject = function (opt_includeInstance) {
    return proto.mappa.InternoPb.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mappa.InternoPb} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mappa.InternoPb.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        codCircEu: jspb.Message.getFieldWithDefault(msg, 1, 0),
        codCom: jspb.Message.getFieldWithDefault(msg, 2, 0),
        codProv: jspb.Message.getFieldWithDefault(msg, 3, 0),
        codReg: jspb.Message.getFieldWithDefault(msg, 4, 0),
        eleT: jspb.Message.getFieldWithDefault(msg, 5, 0),
        percVot: jspb.Message.getFieldWithDefault(msg, 6, 0),
        skBianche: jspb.Message.getFieldWithDefault(msg, 7, 0),
        skNulle: jspb.Message.getFieldWithDefault(msg, 8, 0),
        votT: jspb.Message.getFieldWithDefault(msg, 9, 0),
        szPerv: jspb.Message.getFieldWithDefault(msg, 10, 0),
        szPres: jspb.Message.getFieldWithDefault(msg, 11, 0),
        szTot: jspb.Message.getFieldWithDefault(msg, 12, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mappa.InternoPb}
 */
proto.mappa.InternoPb.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mappa.InternoPb();
  return proto.mappa.InternoPb.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mappa.InternoPb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mappa.InternoPb}
 */
proto.mappa.InternoPb.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCodCircEu(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCodCom(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCodProv(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCodReg(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setEleT(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPercVot(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSkBianche(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSkNulle(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVotT(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSzPerv(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSzPres(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSzTot(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mappa.InternoPb.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.mappa.InternoPb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mappa.InternoPb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mappa.InternoPb.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCodCircEu();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getCodCom();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getCodProv();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getCodReg();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getEleT();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
  f = message.getPercVot();
  if (f !== 0) {
    writer.writeUint32(6, f);
  }
  f = message.getSkBianche();
  if (f !== 0) {
    writer.writeUint32(7, f);
  }
  f = message.getSkNulle();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getVotT();
  if (f !== 0) {
    writer.writeUint32(9, f);
  }
  f = message.getSzPerv();
  if (f !== 0) {
    writer.writeUint32(10, f);
  }
  f = message.getSzPres();
  if (f !== 0) {
    writer.writeUint32(11, f);
  }
  f = message.getSzTot();
  if (f !== 0) {
    writer.writeUint32(12, f);
  }
};

/**
 * optional uint32 cod_circ_eu = 1;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getCodCircEu = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setCodCircEu = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint32 cod_com = 2;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getCodCom = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setCodCom = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 cod_prov = 3;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getCodProv = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setCodProv = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 cod_reg = 4;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getCodReg = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setCodReg = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 ele_t = 5;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getEleT = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setEleT = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional uint32 perc_vot = 6;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getPercVot = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setPercVot = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional uint32 sk_bianche = 7;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getSkBianche = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setSkBianche = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional uint32 sk_nulle = 8;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getSkNulle = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setSkNulle = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional uint32 vot_t = 9;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getVotT = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setVotT = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * optional uint32 sz_perv = 10;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getSzPerv = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setSzPerv = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

/**
 * optional uint32 sz_pres = 11;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getSzPres = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setSzPres = function (value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};

/**
 * optional uint32 sz_tot = 12;
 * @return {number}
 */
proto.mappa.InternoPb.prototype.getSzTot = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.InternoPb} returns this
 */
proto.mappa.InternoPb.prototype.setSzTot = function (value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mappa.CandidatePb.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mappa.CandidatePb.prototype.toObject = function (opt_includeInstance) {
    return proto.mappa.CandidatePb.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mappa.CandidatePb} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mappa.CandidatePb.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        listaList: jspb.Message.toObjectList(
          msg.getListaList(),
          proto.mappa.ListaPb.toObject,
          includeInstance
        ),
        pos: jspb.Message.getFieldWithDefault(msg, 2, 0),
        votiUni: jspb.Message.getFieldWithDefault(msg, 3, 0),
        percUni: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mappa.CandidatePb}
 */
proto.mappa.CandidatePb.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mappa.CandidatePb();
  return proto.mappa.CandidatePb.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mappa.CandidatePb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mappa.CandidatePb}
 */
proto.mappa.CandidatePb.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.mappa.ListaPb();
        reader.readMessage(
          value,
          proto.mappa.ListaPb.deserializeBinaryFromReader
        );
        msg.addLista(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPos(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVotiUni(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPercUni(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mappa.CandidatePb.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.mappa.CandidatePb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mappa.CandidatePb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mappa.CandidatePb.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getListaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mappa.ListaPb.serializeBinaryToWriter
    );
  }
  f = message.getPos();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getVotiUni();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getPercUni();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
};

/**
 * repeated ListaPb lista = 1;
 * @return {!Array<!proto.mappa.ListaPb>}
 */
proto.mappa.CandidatePb.prototype.getListaList = function () {
  return /** @type{!Array<!proto.mappa.ListaPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mappa.ListaPb, 1)
  );
};

/**
 * @param {!Array<!proto.mappa.ListaPb>} value
 * @return {!proto.mappa.CandidatePb} returns this
 */
proto.mappa.CandidatePb.prototype.setListaList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.mappa.ListaPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mappa.ListaPb}
 */
proto.mappa.CandidatePb.prototype.addLista = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.mappa.ListaPb,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mappa.CandidatePb} returns this
 */
proto.mappa.CandidatePb.prototype.clearListaList = function () {
  return this.setListaList([]);
};

/**
 * optional uint32 pos = 2;
 * @return {number}
 */
proto.mappa.CandidatePb.prototype.getPos = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.CandidatePb} returns this
 */
proto.mappa.CandidatePb.prototype.setPos = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 voti_uni = 3;
 * @return {number}
 */
proto.mappa.CandidatePb.prototype.getVotiUni = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.CandidatePb} returns this
 */
proto.mappa.CandidatePb.prototype.setVotiUni = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 perc_uni = 4;
 * @return {number}
 */
proto.mappa.CandidatePb.prototype.getPercUni = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.CandidatePb} returns this
 */
proto.mappa.CandidatePb.prototype.setPercUni = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.mappa.ListaPb.prototype.toObject = function (opt_includeInstance) {
    return proto.mappa.ListaPb.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.mappa.ListaPb} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.mappa.ListaPb.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        pos: jspb.Message.getFieldWithDefault(msg, 1, 0),
        voti: jspb.Message.getFieldWithDefault(msg, 2, 0),
        perc: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mappa.ListaPb}
 */
proto.mappa.ListaPb.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mappa.ListaPb();
  return proto.mappa.ListaPb.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mappa.ListaPb} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mappa.ListaPb}
 */
proto.mappa.ListaPb.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPos(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setVoti(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPerc(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mappa.ListaPb.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.mappa.ListaPb.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mappa.ListaPb} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mappa.ListaPb.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPos();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getVoti();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getPerc();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
};

/**
 * optional uint32 pos = 1;
 * @return {number}
 */
proto.mappa.ListaPb.prototype.getPos = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.ListaPb} returns this
 */
proto.mappa.ListaPb.prototype.setPos = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint32 voti = 2;
 * @return {number}
 */
proto.mappa.ListaPb.prototype.getVoti = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.ListaPb} returns this
 */
proto.mappa.ListaPb.prototype.setVoti = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 perc = 3;
 * @return {number}
 */
proto.mappa.ListaPb.prototype.getPerc = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.mappa.ListaPb} returns this
 */
proto.mappa.ListaPb.prototype.setPerc = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

goog.object.extend(exports, proto.mappa);
