import Config from '@/lib/config';
import * as React from 'react';
import './index.scss';

export interface HeaderProps {
  title: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => (
  <div className="Header">
    <h1 className="Header__title">{title}</h1>
    <div>
      <a href="https://www.youtrend.it/" target="__blank">
        <img
          src={`${Config.publicUrl}/assets/youtrend.svg`}
          alt="YouTrend"
          className="Header__YouTrendLogo"
        />
      </a>
      {process.env.REACT_APP_FONT_FILE === 'agiFont' && (
        <a href="https://www.agi.it/" target="__blank">
          <img
            src={`${Config.publicUrl}/assets/AGI.svg`}
            alt="AGI"
            className="Header__YouTrendLogo"
          />
        </a>
      )}
    </div>
  </div>
);
