import Config from '@/lib/config';
import { formatNumber } from '@/lib/helpers';
import { DynamicTurnout } from '@/lib/model';
import * as React from 'react';
import './index.scss';

export interface LiveProps {
  currentNumber: number;
  total: number;
  dynamicTurnout: DynamicTurnout[];
}

export const Live: React.FC<LiveProps> = ({
  currentNumber,
  total,
  dynamicTurnout,
}) => {
  const comunicationNo = dynamicTurnout.find(el => el.enti?.ente_p?.cod === 17);
  let comAtt = comunicationNo?.enti?.ente_p?.com[0]?.com_att;

  return (
    <div className="Live">
      <div className="Live__header">
        <span>
          Sezioni scrutinate:
          <br />
          <b>{formatNumber(currentNumber)}</b> su <b>{formatNumber(total)}</b>
        </span>

        {(comAtt ?? 0) === 4 && (currentNumber / total) * 100 < 100 && (
          <div className="Live__header__button">
            <img
              src={`${Config.publicUrl}/assets/liveIcon.svg`}
              alt="Live Results"
            />
            <span>LIVE</span>
          </div>
        )}
      </div>
      {(currentNumber / total) * 100 < 100 && (
        <div className="Live__progress">
          <div
            className="Live__progress__bar"
            style={{
              width: `${(currentNumber / total) * 100}%`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
