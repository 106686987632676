import AdminDivisionsCamera from '@/data/mapping-camera-comuni7917.json';

import {
  getCoalitionColor,
  getCoalitionColorForPopup,
  getFullNameCandidate,
  getMostraDettaglio,
  getName,
  getPartyColor,
} from '@/lib/candidatesHelpers';
import {
  convertIdComune,
  convertIdComuneToNomeComune,
  convertIdProvince,
  convertIdProvinceNomeProvincia,
} from '@/lib/convertId';
import { formatNumber, parsePercentage } from '@/lib/helpers';
import { getLevelSettings } from '@/lib/levelsHelpers';
import {
  AutocompleteOption,
  DataLevel,
  DynamicTurnout,
  Region,
  Result,
  ResultAPI,
  ResultCodes,
  Year,
} from '@/lib/model';
import { formatDecimal } from '@/utils/format-decimal.util';
import {
  getAffluenzaByLivelloEleT,
  getAffluenzaByLivelloPerc,
  getAffluenzaByLivelloVoti,
} from './affluenzaHelper';
import { ComuniPb } from './mappa_pb';

const API_URL = 'https://2024-real-time-data.s3.eu-central-1.amazonaws.com/'; // Config.API_URL;

let fetchController = new AbortController();
let fetchDynamicTurnoutController = new AbortController();
let fetchDynamicTurnoutItalyController = new AbortController();

/**
 * Formatta il nome del luogo in base al livello
 * @param resultInt
 * @param level
 */
function formatPlaceName(resultInt: ResultAPI['int'], level: DataLevel) {
  let placeName: string | undefined = '';

  if (level === DataLevel.MAIN) {
    placeName = resultInt.l_terr;
  } else if (level === DataLevel.CIRCOSCRIZIONE)
    placeName = resultInt.desc_circ || resultInt.desc_circ_eu;
  else if (level === DataLevel.REGIONE) {
    placeName = resultInt.desc_reg;
  } else if (level === DataLevel.PROVINCIA) {
    placeName = convertIdProvinceNomeProvincia(
      resultInt.cod_reg,
      resultInt.cod_prov
    );
  } else {
    placeName = convertIdComuneToNomeComune(
      resultInt.cod_reg,
      resultInt.cod_prov,
      resultInt.cod_com
    );
  }

  return placeName || '';
}

/**
 * Genera le coalizioni a partire dal risultato ricevuto dall'api
 * @param result
 * @param year
 * @param region
 * @param level
 */
function generateCoalitions(
  result: ResultAPI,
  year: Year,
  region: Region,
  level: DataLevel
): any {
  let list: any[] | undefined = result.cand;

  if (year === Year.Y2024) {
    list = result.liste;
  }

  return list
    ?.map(coalition => ({
      coalitionId: coalition.pos,
      label: getName(year, region, true, coalition),
      fullName: getFullNameCandidate(
        year,
        region,
        true,
        coalition,
        result.isMultiCollegio
      ),
      votes: formatNumber(coalition.voti_uni || coalition.voti || 0),
      percentage: parsePercentage(coalition.perc_uni || coalition.perc || '0'),
      fillColor: (function () {
        let color = getCoalitionColor(year, region, level, coalition);
        return color;
      })(),
      fillColorPopup: getCoalitionColorForPopup(year, region, level, coalition),
      candidates: getMostraDettaglio(year, region, coalition.pos)
        ? coalition.liste
            ?.map((candidate: any) => ({
              coalitionId: coalition.pos,
              candidateId: candidate.pos,
              label: getName(year, region, false, candidate), // change to this when in PROD -> list.desc_lis,
              fullName: getFullNameCandidate(
                year,
                region,
                false,
                candidate,
                result.isMultiCollegio
              ),
              votes: formatNumber(candidate.voti),
              percentage: parsePercentage(candidate.perc),
              fillColor: getPartyColor(year, region, candidate.pos),
            }))
            .sort((a: any, b: any) => {
              return (b?.percentage || 0) - (a?.percentage || 0);
            })
        : [],
    }))
    .sort(({ percentage: a }, { percentage: b }) => b - a);
}

const parseTotalElectors = (
  dynamicTurnout: DynamicTurnout[],
  year: Year,
  level: DataLevel,
  result: ResultAPI,
  codCirc: string | undefined
) => {
  console.log(
    'parseTotalElectors getAffluenzaByLivelloEleT ',
    year,
    level,
    result,
    codCirc,
    getAffluenzaByLivelloEleT(
      dynamicTurnout,
      level,
      result.int.cod_reg,
      result.int.cod_prov,
      result.int.cod_com,
      codCirc
    )
  );

  console.log(
    'parseTotalElectors value ',
    year,
    level,
    result,
    codCirc,
    result.int.ele_t
  );

  if (Year.Y2024 === year) {
    const calc = getAffluenzaByLivelloEleT(
      dynamicTurnout,
      level,
      result.int.cod_reg,
      result.int.cod_prov,
      result.int.cod_com,
      codCirc
    );

    if (calc === 0) {
      return formatNumber(result.int.ele_t);
    } else {
      return formatNumber(calc);
    }
  } else {
    return formatNumber(result.int.ele_t);
  }

  // const value =
  //   Year.Y2024 === year
  //     ? formatNumber(
  //         getAffluenzaByLivelloEleT(
  //           dynamicTurnout,
  //           level,
  //           result.int.cod_reg,
  //           result.int.cod_prov,
  //           result.int.cod_com,
  //           codCirc
  //         )
  //       )
  //     : formatNumber(result.int.ele_t);
  // return value;
};

const parseTotalVoters = (
  dynamicTurnout: DynamicTurnout[],
  year: Year,
  level: DataLevel,
  result: ResultAPI,
  codCirc: string | undefined
) => {
  if (Year.Y2024 === year) {
    const calc = getAffluenzaByLivelloVoti(
      dynamicTurnout,
      level,
      result.int.cod_reg,
      result.int.cod_prov,
      result.int.cod_com,
      codCirc
    );

    if (calc === 0) {
      return formatNumber(result.int.vot_t);
    } else {
      return formatNumber(calc);
    }
  } else {
    return formatNumber(result.int.vot_t);
  }

  // const value =
  //   Year.Y2024 === year
  //     ? formatNumber(
  //         getAffluenzaByLivelloVoti(
  //           dynamicTurnout,
  //           level,
  //           result.int.cod_reg,
  //           result.int.cod_prov,
  //           result.int.cod_com,
  //           codCirc
  //         )
  //       )
  //     : formatNumber(result.int.vot_t);
  // return value;
};

function convertCodRegToCodCirc(result: ResultAPI) {
  if (result.int.cod_circ_eu) {
    return `${result.int.cod_circ_eu}`;
  } else if (result.int.cod_circ) {
    return `${result.int.cod_circ}`;
  }

  const codReg = `${result.int.cod_reg}`;
  // circoscrizione 1 -> regioni 1,2,3,7
  // circoscrizione 2 -> regioni 4,5,6,8
  // circoscrizione 3 -> regioni 9,10,11,12
  // circoscrizione 4 -> regioni 13,14,15,16,17,18
  // circoscrizione 5 -> regioni 19,20
  let codCirc;

  if (codReg === '1' || codReg === '2' || codReg === '3' || codReg === '7') {
    codCirc = '1';
  } else if (
    codReg === '4' ||
    codReg === '5' ||
    codReg === '6' ||
    codReg === '8'
  ) {
    codCirc = '2';
  } else if (
    codReg === '9' ||
    codReg === '10' ||
    codReg === '11' ||
    codReg === '12'
  ) {
    codCirc = '3';
  } else if (
    codReg === '13' ||
    codReg === '14' ||
    codReg === '15' ||
    codReg === '16' ||
    codReg === '17' ||
    codReg === '18'
  ) {
    codCirc = '4';
  } else if (codReg === '19' || codReg === '20') {
    codCirc = '5';
  }

  return codCirc;
}

// totale voti validi = votanti - (schede bianche + schede nulle)
function parseTotalValidVotes(
  result: ResultAPI,
  year: Year,
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  codCirc: string | undefined
) {
  let totalVoters: number;

  if (Year.Y2024 === year) {
    totalVoters = getAffluenzaByLivelloVoti(
      dynamicTurnout,
      level,
      result.int.cod_reg,
      result.int.cod_prov,
      result.int.cod_com,
      codCirc
    );

    if (+totalVoters === 0 || isNaN(+totalVoters)) {
      console.log(
        'getAffluenzaByLivelloPerc do la percentuale dei voti',
        result.int.perc_vot
      );
      totalVoters = result.int.vot_t;
    }
  } else {
    totalVoters = result.int.vot_t;
  }

  return formatNumber(
    +totalVoters - (result.int.sk_bianche || 0) - (result.int.sk_nulle || 0)
  );

  // let totalVoters: number;
  // if (Year.Y2024 === year) {
  //   totalVoters = getAffluenzaByLivelloVoti(
  //     dynamicTurnout,
  //     level,
  //     result.int.cod_reg,
  //     result.int.cod_prov,
  //     result.int.cod_com,
  //     codCirc
  //   );
  // } else {
  //   totalVoters = result.int.vot_t;
  // }
  //
  // return formatNumber(
  //   +totalVoters - (result.int.sk_bianche || 0) - (result.int.sk_nulle || 0)
  // );
}

function parseAffluenza(
  result: ResultAPI,
  year: Year,
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  codCirc: string | undefined
) {
  if (level === DataLevel.MAIN) {
    debugger;
  }
  if (Year.Y2024 === year) {
    const calc = getAffluenzaByLivelloPerc(
      dynamicTurnout,
      level,
      codCirc,
      result.int.cod_reg,
      result.int.cod_prov,
      result.int.cod_com
    );

    console.log(
      'getAffluenzaByLivelloPerc@@ affluenza',
      parseFloat(calc?.replace(',', '.')),
      typeof calc
    );
    console.log(
      'getAffluenzaByLivelloPerc@@ result.int.perc_vot',
      result.int.perc_vot,
      codCirc
    );

    if (
      parseFloat(calc?.replace(',', '.')) === 0 ||
      isNaN(parseFloat(calc?.replace(',', '.')))
    ) {
      console.log(
        'getAffluenzaByLivelloPerc do la percentuale dei voti',
        result.int.perc_vot
      );
      return formatDecimal(result.int.perc_vot, true);
    } else {
      return formatDecimal(calc, true);
    }
  } else {
    return formatDecimal(result.int.perc_vot, true);
  }

  //
  // return Year.Y2024 === year
  //     ? `${formatDecimal(
  //         getAffluenzaByLivelloPerc(
  //             dynamicTurnout,
  //             level,
  //             codCirc,
  //             result.int.cod_reg,
  //             result.int.cod_prov,
  //             result.int.cod_com
  //         ),
  //         true
  //     )}`
  //     : `${formatDecimal(parsePercentage(result.int.perc_vot), true)}`;
}

export const parseResult = (
  result: ResultAPI,
  year: Year,
  region: Region,
  level: DataLevel,
  dynamicTurnout: DynamicTurnout[]
) => {
  const codCirc = convertCodRegToCodCirc(result);
  const voters = parseTotalVoters(dynamicTurnout, year, level, result, codCirc);

  const res = {
    level,
    year,
    region,
    placeName: formatPlaceName(result?.int, level),
    showVoti: true,
    scrutinized: {
      current: result.int.sz_perv || result.int.sz_pres || 0,
      total: result.int.sz_tot || 0,
    },
    coalitions: generateCoalitions(result, year, region, level),
    idCodes: {
      codReg: `${result.int.cod_reg}`,
      codCirc: codCirc,
      codProv: `${result.int.cod_prov}`,
      codClPlu: `${result.int.cod_plu}`,
      codClUni: `${result.int.cod_cl_uni}`,
      codCom: `${result.int.cod_com}`,
    },
    summary: {
      turnout: {
        label: 'Affluenza',
        value: parseAffluenza(result, year, dynamicTurnout, level, codCirc),
      },
      totalElectors: {
        label: 'Totale Elettori',
        value: parseTotalElectors(dynamicTurnout, year, level, result, codCirc),
      },
      totalVoters: {
        label: 'Totale Votanti',
        value: voters,
      },
      blankCards: {
        label: 'Schede Bianche',
        value: formatNumber(result.int.sk_bianche),
      },
      contestedCards: {
        label: 'Schede Contestate',
        value:
          Year.Y2019 === year || Year.Y2022 === year
            ? '-1'
            : formatNumber(result.int.sk_contestate),
      },
      nullCards: {
        label:
          Year.Y2019 === year || Year.Y2022 === year
            ? 'Schede Nulle o Contestate'
            : 'Schede Nulle',
        value: formatNumber(result.int.sk_nulle),
      },
      validVotes: {
        //"Totali voti validi" (=Votanti - (Bianche + Nulle))
        label: 'Totali Voti Validi',
        value:
          parseTotalValidVotes(result, year, dynamicTurnout, level, codCirc) ||
          '----',
      },
    },
  };
  return res;
};

/**
 * Converte il risultato ricevuto dall'api in un modello utilizzabile sulla mappa
 * @param result Risultato API
 * @param year  Filtro Anno
 * @param region Filtro per Regione
 * @param level Livello da impostare sull mappa
 */
export const parseResultToMapLayer = (
  result: ResultAPI,
  year: Year,
  region: Region,
  level: DataLevel
) => {
  const { variable } = getLevelSettings(level);

  let list: any[] | undefined = result.cand;

  if (year === Year.Y2024) {
    list = result.liste;
  }

  const winningCandidate = list?.sort((a, b) => {
    const aPerc = parsePercentage(a.perc_uni || a.perc || '0');
    const bPerc = parsePercentage(b.perc_uni || b.perc || '0');
    return bPerc - aPerc;
  })[0];

  const votes = winningCandidate?.voti_uni || winningCandidate?.voti || 0;
  const perc = winningCandidate?.perc_uni || winningCandidate?.perc || null;

  let color = '#D9D9D9'; // default se voti 0 o perc null

  if (region !== Region.ALL && winningCandidate === undefined) {
    // console.log('check', result);
  } else if (region === Region.ALL || (votes > 0 && perc !== null)) {
    // console.log("%c PRE - GETCOALITIONCOLOR", "color:" + color + ";font-weight:bold;font-size: 30px", year, region, level, { pos: result.pos } );

    if (region === Region.ALL) {
      color = getCoalitionColor(year, region, level, winningCandidate);
      // DA CAPIRE QUALE METTERE
      // color = getCoalitionColor(year, region, level, { pos: result.pos });
    } else {
      color = getCoalitionColor(year, region, level, winningCandidate);
    }
    // console.log( 'provaprova', year, region, level, winningCandidate, result.pos, color )
  }

  let idFromVariable: string = result.int[variable] as string; // default
  if (level === DataLevel.CIRCOSCRIZIONE) {
    idFromVariable = result.int.cod_circ || result.int.cod_circ_eu || '';
  } else if (level === DataLevel.REGIONE) {
    idFromVariable = result.int.cod_reg || '';
  } else if (level === DataLevel.PROVINCIA) {
    idFromVariable = convertIdProvince(
      `${result.int.cod_reg}`,
      `${result.int.cod_prov}`
    );
  } else if (level === DataLevel.COMUNE) {
    idFromVariable = convertIdComune(
      `${result.int.cod_reg}`,
      `${result.int.cod_prov}`,
      `${result.int.cod_com}`
    );
  }

  return {
    fillColor: color,
    opacity: 1, // full color of the winning coalition // parsePercentage(winningCandidate?.perc) / 100,
    layerId: idFromVariable,
    coordinates: [],
  };
};

const fetchResults = async (
  abort: boolean,
  year: Year,
  region: Region,
  level: DataLevel,
  codes?: ResultCodes,
  circumscription?: string
): Promise<ResultAPI | ResultAPI[] | null> => {
  const yearStr = convertYearEnumToStr(year);
  const yearFolder = year === Year.Y2024 ? '2024-europee' : yearStr;
  let url = `${API_URL}${yearFolder}/eu_`;
  let urlLevel = 'ita';

  if (level === DataLevel.COMUNE) {
    urlLevel = 'com';
  } else if (level === DataLevel.PROVINCIA) {
    urlLevel = 'prov';
  } else if (level === DataLevel.REGIONE) {
    urlLevel = 'reg';
  } else if (level === DataLevel.CIRCOSCRIZIONE) {
    urlLevel = 'circ';
  } else if (level === DataLevel.MAIN) {
    urlLevel = 'ita';
  }
  url += `${urlLevel}_${yearStr}.json`;

  try {
    if (abort) {
      fetchController.abort();
      fetchController = new AbortController();
    }

    if (
      level === DataLevel.COMUNE &&
      (year === Year.Y2019 || year === Year.Y2022 || year === Year.Y2024)
    ) {
      // start call protobuf

      // const startTime = new Date().getTime();

      const blobToUint8Array = (blob: Blob): Promise<Uint8Array> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
              resolve(new Uint8Array(reader.result));
            } else {
              reject(new Error('Unable to convert Blob to Uint8Array'));
            }
          };

          reader.onerror = () => {
            reject(new Error('FileReader error'));
          };

          reader.readAsArrayBuffer(blob);
        });
      };

      // let resultAllComuniFromStorage = localStorage.getItem(`eu_com_${yearStr}.pb`)
      // console.log( `eu_com_${yearStr}.pb`, resultAllComuniFromStorage )
      // if( resultAllComuniFromStorage !== null ) {
      //   console.log(`eu_com_${yearStr}.pb`, 'mannaccia')
      //   return JSON.parse( resultAllComuniFromStorage );
      // }

      const cachedData = getOnStorage(`eu_com_${yearStr}.pb`, year);
      if (cachedData) {
        return cachedData;
      }

      const protobuf_url = `${API_URL}${yearFolder}/eu_com_${yearStr}.pb`;
      const protoResponse = await fetch(protobuf_url, {
        signal: fetchController.signal,
      });
      const protos = await protoResponse.blob();
      const comuniPb = ComuniPb.deserializeBinary(
        await blobToUint8Array(protos)
      );
      const resultAllComuni: Array<ResultAPI> = comuniPb
        .getComuniList()
        .map(comunePb => {
          const internoPb = comunePb.getInterno()!;

          const intObj = {
            cod_circ_eu: internoPb.getCodCircEu().toString(),
            cod_com: internoPb.getCodCom().toString(),
            cod_prov: internoPb.getCodProv().toString(),
            cod_reg: internoPb.getCodReg().toString(),
            ele_t: internoPb.getEleT(),
            perc_vot: (internoPb.getPercVot() / 100)
              .toString()
              .replace('.', ','),
            sk_bianche: internoPb.getSkBianche(),
            sk_nulle: internoPb.getSkNulle(),
            vot_t: internoPb.getVotT(),
            sk_contestate: 0,
            sz_perv: internoPb.getSzPerv(),
            sz_tot: internoPb.getSzTot(),
          };

          if (year === Year.Y2024) {
            // non ho cand ma direttamente le liste
            const listeList: any = comunePb.getListeList().map(listaPb => {
              return {
                pos: listaPb.getPos(),
                voti: listaPb.getVoti(),
                perc: (listaPb.getPerc() / 100).toString().replace('.', ','),
              };
            });
            const comune2024: ResultAPI = {
              int: intObj,
              liste: listeList,
              cand: undefined,
            };
            return comune2024;
          } else {
            const candsAll: ResultAPI['cand'] = comunePb
              .getCandidatiList()
              .map(candPb => {
                const candList: any = candPb.getListaList().map(listaPb => {
                  return {
                    pos: listaPb.getPos(),
                    voti: listaPb.getVoti(),
                    perc: (listaPb.getPerc() / 100)
                      .toString()
                      .replace('.', ','),
                  };
                });
                return {
                  pos: candPb.getPos(),
                  voti_uni: candPb.getVotiUni(),
                  perc_uni: (candPb.getPercUni() / 100)
                    .toString()
                    .replace('.', ','),
                  liste: candList,
                  voti: 0,
                  perc: '',
                };
              });

            const comune: ResultAPI = {
              int: intObj,
              cand: candsAll,
            };

            return comune;
          }
        });

      // const endTime = new Date().getTime();
      // console.log(`### Total time: [${endTime - startTime}].`);

      // console.log(`### FINAL resultAllComuni: ${JSON.stringify(resultAllComuni)}`);
      // console.log(`### FINAL resultAll length: ${resultAllComuni.length}`);

      // localStorage.setItem(`eu_com_${yearStr}.pb`, JSON.stringify(resultAllComuni) );

      writeOnStorage(`eu_com_${yearStr}.pb`, resultAllComuni, year);

      return resultAllComuni;
    }

    // end call protobuf

    const cachedData = getOnStorage(url, year);
    if (cachedData) {
      return cachedData;
    }

    let res: ResultAPI | ResultAPI[] = await (
      await fetch(url, {
        signal: fetchController.signal,
      })
    )
      .json()
      .catch(_ => {
        return null;
      });

    if (level === DataLevel.COMUNE) {
      if (
        codes === undefined ||
        (codes !== undefined && codes.codProv === undefined)
      )
        return res;
      else {
        return res as any[];
      }
    }

    const isResultAPI = (res: ResultAPI | ResultAPI[]): res is ResultAPI => {
      return (res as ResultAPI).int !== undefined;
    };

    if (!isResultAPI(res)) {
      res?.map((result: ResultAPI) => {
        if (result?.int) {
          //@ts-ignore
          if (result.int?.cod_pr) {
            // @ts-ignore
            result.int['cod_prov'] = (result.int['cod_pr'] || '') + '';
            // @ts-ignore
            result.int['desc_prov'] = (result.int['desc_pr'] || '') + '';
          }
        }
      });
    }

    writeOnStorage(url, res, year);
    return res;
  } catch {
    return null;
  }
};

function getOnStorage(key: string, year: Year) {
  if (year === Year.Y2024) return;
  if ((<any>window)['mapStorage'] && (<any>window)['mapStorage'][key]) {
    return (<any>window)['mapStorage'][key];
  }
}
function writeOnStorage(key: string, value: any, year: Year) {
  if (year === Year.Y2024) return;
  if (!(<any>window)['mapStorage']) {
    (<any>window)['mapStorage'] = {};
  }
  (<any>window)['mapStorage'][key] = value;
}

export const getDynamicTurnout = async () => {
  try {
    fetchDynamicTurnoutController.abort();
    fetchDynamicTurnoutController = new AbortController();

    const dynamicTurnout = await (
      await fetch(`${API_URL}2024-europee/votanti/affluenza.json`, {
        signal: fetchDynamicTurnoutController.signal,
      })
    ).json();

    return dynamicTurnout;
  } catch (e) {
    return [];
  }
};

export const getDynamicTurnoutItaly = async () => {
  try {
    fetchDynamicTurnoutItalyController.abort();
    fetchDynamicTurnoutItalyController = new AbortController();

    const dynamicTurnout = await (
      await fetch(`${API_URL}2024-europee/votanti/01.json`, {
        signal: fetchDynamicTurnoutItalyController.signal,
      })
    ).json();

    return dynamicTurnout;
  } catch (e) {
    return [];
  }
};

/**
 * Effettua una chiamata al servizio e modella il risultato per essere utilizzato sulla mappa
 * @param abort
 * @param year
 * @param region
 * @param level
 * @param dynamicTurnout
 * @param codes
 * @param selectedCirc
 */
export const getResults = async (
  abort: boolean,
  year: Year,
  region: Region,
  level: DataLevel,
  dynamicTurnout: DynamicTurnout[],
  codes?: ResultCodes,
  selectedCirc?: string | undefined
): Promise<Result[]> => {
  try {
    // const fromStorage = localStorage.getItem(`${abort}${year}${region}${level}${dynamicTurnout}${codes}${selectedCirc}`);
    //
    // console.log( `${abort}${year}${region}${level}${dynamicTurnout}${codes}${selectedCirc}`, fromStorage)
    //
    // let data
    // if (fromStorage) {
    //   data = JSON.parse(fromStorage);
    // } else {
    let data = await fetchResults(
      abort,
      year,
      region,
      level,
      codes,
      selectedCirc
    );

    console.log('@@@@', data, level, year, codes);
    //   localStorage.setItem(`${abort}${year}${region}${level}${dynamicTurnout}${codes}${selectedCirc}`, JSON.stringify(data) );
    // }

    data = !data ? [] : Array.isArray(data) ? data : [data];

    const res = data
      .filter(el => el)
      .map((result: ResultAPI) => {
        return {
          ...parseResult(result, year, region, level, dynamicTurnout),
          mapLayer: parseResultToMapLayer(result, year, region, level),
        };
      });

    return res;
  } catch (e) {
    console.error('error fetching results', e);
    return [];
  }
};

export const getAutocompleteOptions = () => {
  const data = AdminDivisionsCamera as AutocompleteOption[];

  return data
    .reduce((acc: AutocompleteOption[], el) => {
      if (
        acc.some(
          option =>
            option.cod_comune === el.cod_comune &&
            option.cod_provincia === el.cod_provincia
        )
        // Limit data to Lombardia and Lazio
        // TODO DA ELIMINARE || (el.cod_regione !== '3' && el.cod_regione !== '12' && el.cod_regione !== '17')
      ) {
        return acc;
      }

      return [...acc, el];
    }, [])
    .sort((a, b) => {
      // should be:
      // a.cod_provincia.localeCompare(b.cod_provincia);

      return a.nome_provincia.localeCompare(b.nome_provincia);
    });
};

export const getLastVersion = async () => {
  return await (
    await fetch(
      'https://2024-real-time-data.s3.eu-central-1.amazonaws.com/2024-europee/last_version.json' //TODO DA CAMBIARE CON ENV
    )
  ).json();
};

export const getLastVersionTurnoutItaly = async () => {
  return await (
    await fetch(
      'https://2024-real-time-data.s3.eu-central-1.amazonaws.com/2024-europee/last_version_01.json' //TODO DA CAMBIARE CON ENV
    )
  ).json();
};

/**
 * Restituisce le informazioni di tutti i comuni di una determinata provincia
 * @param regionCode
 * @param provinceCode
 * @param year
 * @param level
 * @param dynamicTurnout
 */
export const getMunicipalities = async (
  regionCode: string,
  provinceCode: string,
  year: Year,
  level: DataLevel,
  dynamicTurnout: DynamicTurnout[]
) => {
  // console.log(regionCode, provinceCode);
  if (!regionCode && !provinceCode) return null;

  const data = await (
    await fetch(
      `${API_URL}${convertYearEnumToStr(year)}/07${RIGHT(regionCode)}${RIGHT(
        provinceCode,
        '0',
        3
      )}.json`
    )
  ).json();

  return data.map((result: ResultAPI) => ({
    ...parseResult(result, year, +regionCode as Region, level, dynamicTurnout),
    mapLayer: parseResultToMapLayer(result, year, +regionCode as Region, level),
  }));

  //https://2023-real-time-data.s3.eu-central-1.amazonaws.com/2022/0703057.json
};

/**
 * UTILS
 * @param str
 * @param fillChar
 * @param num
 * @constructor
 */
const RIGHT = (
  str: string | number,
  fillChar: string = '0',
  num: number = 2
) => {
  let fillStr = '';

  for (let i = 0; i <= num; i++) {
    fillStr += fillChar;
  }

  fillStr += (str || '') + '';

  return fillStr.substring(fillStr.length - num, fillStr.length);
};

/**
 *
 */
const convertYearEnumToStr = (year: Year) => {
  switch (year) {
    case Year.Y2019:
      return '2019';
    case Year.Y2022:
      return '2022';
    case Year.Y2024:
      return '2024';
  }
};
