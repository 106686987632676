import Config from '@/lib/config';
import { MainProvider } from '@/lib/context';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import * as React from 'react';
import { MapProvider } from 'react-map-gl';
import './index.scss';
import { Main } from './main';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
  },
  typography: {
    fontFamily: Config.fontName,
  },
});

export const App: React.FC = () => {
  // console.log(
  //   'process.env.REACT_APP_FONT_FILE ',
  //   process.env.REACT_APP_FONT_FILE
  // );

  if (process.env.REACT_APP_FONT_FILE === 'agiFont') {
    require('./index-agi.scss');
  }

  if (process.env.REACT_APP_FONT_FILE === 'qnFont') {
    if (process.env.REACT_APP_FONT_2_COLUMN === 'true') {
      require('./index-qn2column.scss');
    } else {
      require('./index-qn.scss');
    }
  }

  if (process.env.REACT_APP_FONT_FILE === 'soleSans') {
    require('./index-sole.scss');
  }

  if (process.env.REACT_APP_FONT_FILE === 'skyFont') {
    require('./index-sky.scss');
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MapProvider>
          <MainProvider>
            <Main />
          </MainProvider>
        </MapProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
