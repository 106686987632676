import Config from '@/lib/config';
import { DataLevel, Region, Year } from '@/lib/model';

export const FILTERS = [
  {
    label: 'Circoscrizione',
    value: DataLevel.CIRCOSCRIZIONE,
  },
  {
    label: 'Regione',
    value: DataLevel.REGIONE,
  },
  {
    label: 'Provincia',
    value: DataLevel.PROVINCIA,
  },
  {
    label: 'Comune',
    value: DataLevel.COMUNE,
  },
];

export const REGIONS = [
  {
    label: 'Comuni',
    value: Region.ALL,
  },
].filter(r => {
  // show active regions depending on client
  if (Config.activeRegions === 'all') {
    return true;
  }

  return r.label.toLowerCase() === Config.activeRegions;
});

export const YEARS = [
  {
    label: '2019 - Europee',
    value: Year.Y2019,
  },
  {
    label: '2022 - Politiche',
    value: Year.Y2022,
  },
  {
    label: '2024 - Europee',
    value: Year.Y2024,
  },
];
