export const parsePercentage = (result: string) => {
  if (!result) {
    return 0;
  }

  return parseFloat(`${result}`.replace(',', '.'));
};

export const formatNumber = (num: number | undefined) => {
  if (!num || isNaN(num)) {
    return '0';
  }
  return new Intl.NumberFormat('it-IT', {}).format(num);
};
