import COORDINATE_COMUNI from '@/data/mapping-regione-coodinate.json';
import { useMainContext } from '@/lib/context';
import { FILTERS } from '@/lib/fixedTypes';
import {
  getAutocompleteOptions,
  getDynamicTurnout,
  getDynamicTurnoutItaly,
  getResults,
} from '@/lib/getResults';
import { getMapSettings } from '@/lib/levelsHelpers';
import {
  AutocompleteOption,
  DataLevel,
  Result,
  ResultCodes,
} from '@/lib/model';
import * as React from 'react';
import { MainComponent } from './MainComponent';

export const Main = () => {
  const [searchCodes, setSearchCodes] = React.useState<ResultCodes | null>(
    null
  );
  const {
    selectedYear,
    selectedRegion,
    dataLevel,
    setDataLevel,
    setResults,
    setMainResult,
    handleResult,
    selectedResults,
    lastResult,
    lastProvince,
    dynamicTurnout,
    setDynamicTurnout,
    lastVersion,
    setLoadingMap,
    fetchedProvinces,
    setFetchedProvinces,
    fetchedMunicipalities,
    setFetchedMunicipalities,
    setForceCoordinates,
    lastVersionTurnoutItaly,
  } = useMainContext();

  // Dynamic turnout Italy
  React.useEffect(() => {
    const fn = async () => {
      const lastTurnoutMain = await getDynamicTurnoutItaly();

      if (lastTurnoutMain) {
        const index = dynamicTurnout.findIndex(
          (d: any) =>
            'ITALIA' === d.int?.l_terr &&
            d.enti?.enti_f &&
            d.enti?.enti_f[0].tipo !== 'CIRCOSCRIZIONE'
        );

        if (index !== -1) {
          dynamicTurnout[index] = lastTurnoutMain;
        }
      }

      setDynamicTurnout(dynamicTurnout);
    };

    fn();
  }, [lastVersionTurnoutItaly, setDynamicTurnout]);

  // Dynamic turnout
  React.useEffect(() => {
    const fn = async () => {
      const response: any = await Promise.allSettled([
        getDynamicTurnout(),
        getDynamicTurnoutItaly(),
      ]);

      const lastTurnout = response[0].value;
      const lastTurnoutItaly = response[1].value;

      if (lastTurnoutItaly) {
        // const index = dynamicTurnout.findIndex(
        //   (d: any) =>
        //     'ITALIA' === d.int?.l_terr &&
        //     d.enti?.enti_f &&
        //     d.enti?.enti_f[0].tipo !== 'CIRCOSCRIZIONE'
        // );

        // if (index !== -1) {
        lastTurnout[0] = lastTurnoutItaly;
        // }
      }

      setDynamicTurnout(lastTurnout);
    };

    fn();
  }, [lastVersion, setDynamicTurnout]);

  // All results
  React.useEffect(() => {
    // don't fetch results if there is no dynamic turnout or we're currently finding by comune
    if (
      !dynamicTurnout ||
      searchCodes ||
      (lastProvince && lastProvince.region !== selectedRegion)
    ) {
      return;
    }

    const getFetchResults = async () => {
      // console.log( 'getFetchResult', dataLevel)
      // get comuni by province id
      if (dataLevel === DataLevel.COMUNE && lastProvince) {
        // console.log('GETRESULTS livello comune',selectedYear, selectedRegion, dataLevel, dynamicTurnout, lastProvince.idCodes )

        if (fetchedMunicipalities && fetchedMunicipalities.length > 0) {
          return fetchedMunicipalities;
        } else {
          // console.log(
          //   'getResults municipalities',
          //   fetchedMunicipalities,
          //   selectedYear,
          //   selectedRegion,
          //   DataLevel.COMUNE,
          //   dynamicTurnout,
          //   searchCodes,
          //   lastResult?.idCodes?.codCirc
          // );
          const municipalities = await getResults(
            true,
            selectedYear,
            selectedRegion,
            dataLevel,
            dynamicTurnout,
            lastProvince.idCodes,
            lastResult?.idCodes?.codCirc
          );
          setFetchedMunicipalities(municipalities);
          return municipalities;
        }
      }

      // console.log('GETRESULTS livello all data',selectedYear, selectedRegion, dataLevel, dynamicTurnout )
      // get all data

      // if (
      //   dataLevel === DataLevel.PROVINCIA &&
      //   fetchedProvinces &&
      //   fetchedProvinces.length > 0
      // ) {
      //   return fetchedProvinces;
      // }

      // console.log(
      //   'getResults provinces',
      //   fetchedProvinces,
      //   selectedYear,
      //   selectedRegion,
      //   DataLevel.COMUNE,
      //   dynamicTurnout,
      //   searchCodes,
      //   lastResult?.idCodes?.codCirc
      // );
      const provinces = await getResults(
        true,
        selectedYear,
        selectedRegion,
        dataLevel,
        dynamicTurnout,
        undefined,
        lastResult?.idCodes?.codCirc
      );

      if (dataLevel === DataLevel.PROVINCIA) {
        setFetchedProvinces(provinces);
      }

      return provinces;
    };

    const fn = async () => {
      const results = await getFetchResults();
      setResults(results);
    };

    fn();

    // we add last version so getResult refetches when it changes
  }, [
    selectedYear,
    selectedRegion,
    dataLevel,
    lastProvince,
    setResults,
    dynamicTurnout,
    searchCodes,
    lastVersion,
  ]);

  // Main result
  React.useEffect(() => {
    // console.log(
    //   'MAIN RESULT',
    //   selectedYear,
    //   selectedRegion,
    //   DataLevel.REGIONE,
    //   dynamicTurnout
    // );
    const fn = async () => {
      // console.log('GETRESULTS livello regione',selectedYear, selectedRegion, DataLevel.REGIONE, dynamicTurnout );

      let mainResult = (
        await getResults(
          false,
          selectedYear,
          selectedRegion,
          DataLevel.MAIN, // LIVELLO NAZIONALE BY DEFAULT
          dynamicTurnout,
          undefined,
          lastResult?.idCodes?.codCirc
        )
      )[0];

      if (!mainResult) {
        return;
      }

      // let coordinates: number[] = [];
      // switch (selectedRegion) {
      //   default: // Italy
      //     coordinates = [14.2430069, 40.8536072];
      // }

      mainResult = {
        ...mainResult,
        mapLayer: {
          ...mainResult.mapLayer,
          coordinates: [12.3130069, 41.9036072], // Italian coordinates
        },
      };

      setMainResult(mainResult);
    };

    fn();
    // we add last version so getResult refetches when it changes
  }, [
    setMainResult,
    lastVersion,
    selectedYear,
    selectedRegion,
    dynamicTurnout,
  ]);

  // this effect find by comune after its own region has been fully loaded/toggled
  React.useEffect(() => {
    if (
      !selectedResults.length ||
      !searchCodes ||
      (selectedResults[0] !== undefined &&
        selectedResults[0].idCodes.codReg !== undefined &&
        selectedResults[0].idCodes.codReg !== 'undefined' &&
        selectedResults[0].idCodes.codReg !== searchCodes.codReg)
    ) {
      return;
    }

    const fn = async () => {
      try {
        const searchRegioneFn = (province: Result) =>
          province.idCodes.codReg === searchCodes.codReg;
        const searchProvinceFn = (province: Result) =>
          province.idCodes.codProv === searchCodes.codProv;
        const searchComuneFn = (comune: Result) =>
          comune.idCodes.codProv === searchCodes.codProv &&
          comune.idCodes.codCom === searchCodes.codCom;

        let province,
          comune,
          regione: Result | undefined = undefined;

        let dataLevel = DataLevel.COMUNE;
        let resArray = [];

        if (
          dataLevel === DataLevel.COMUNE &&
          lastResult.level === DataLevel.COMUNE &&
          fetchedProvinces?.length > 0 &&
          fetchedMunicipalities?.length > 0
        ) {
          province = fetchedProvinces.find(searchProvinceFn);
          comune = fetchedMunicipalities.find(searchComuneFn);
          setDataLevel(DataLevel.COMUNE);

          handleResult([province!, comune!], false);
        } else {
          setLoadingMap(true);

          if (
            searchCodes.codReg !== undefined &&
            searchCodes.codReg !== 'undefined'
          ) {
            const regions = await getResults(
              false,
              selectedYear,
              selectedRegion,
              DataLevel.REGIONE,
              dynamicTurnout,
              undefined,
              lastResult?.idCodes?.codCirc
            );
            regione = regions.find(searchRegioneFn);
            dataLevel = DataLevel.REGIONE;

            if (regione) resArray.push(regione);
          }

          if (
            searchCodes.codProv !== undefined &&
            searchCodes.codProv !== 'undefined'
          ) {
            // console.log('GETRESULTS livello provinces',selectedYear, selectedRegion, DataLevel.PROVINCIA, dynamicTurnout );
            const provinces = await getResults(
              false,
              selectedYear,
              selectedRegion,
              DataLevel.PROVINCIA,
              dynamicTurnout,
              undefined,
              lastResult?.idCodes?.codCirc
            );

            setFetchedProvinces(provinces);

            province = provinces.find(searchProvinceFn);
            dataLevel = DataLevel.PROVINCIA;

            if (province) resArray.push(province);
          }

          if (
            searchCodes.codCom !== undefined &&
            searchCodes.codCom !== 'undefined'
          ) {
            // console.log('GETRESULTS livello provinces',selectedYear, selectedRegion, DataLevel.COMUNE, dynamicTurnout, searchCodes );
            const comuni = await getResults(
              false,
              selectedYear,
              selectedRegion,
              DataLevel.COMUNE,
              dynamicTurnout,
              searchCodes,
              lastResult?.idCodes?.codCirc
            );

            setFetchedMunicipalities(comuni);

            comune = comuni.find(searchComuneFn);

            dataLevel = DataLevel.COMUNE;
            if (comune) resArray.push(comune);
          }

          setDataLevel(dataLevel);

          handleResult(resArray as any, false);

          if (setForceCoordinates && dataLevel === DataLevel.PROVINCIA) {
            const cod =
              searchCodes.codReg?.length === 1
                ? '0' + searchCodes.codReg
                : searchCodes.codReg;
            //@ts-ignore
            const c = COORDINATE_COMUNI[cod];
            setForceCoordinates([c.long, c.lat]);
          }
        }
      } catch (e) {
        setLoadingMap(false);
        console.error('e', e);
      } finally {
        setSearchCodes(null);
      }
    };

    fn();
  }, [
    selectedResults,
    selectedYear,
    selectedRegion,
    dynamicTurnout,
    searchCodes,
    handleResult,
    setDataLevel,
    setForceCoordinates,
  ]);

  React.useEffect(() => {
    // @ts-ignore
    const defaultValue = window['DEFAULT_SELEZIONE_MAPPA'];

    if (!defaultValue) {
      return;
    }

    const { codRegione, codProvincia, codComune, codCircoscrizione } =
      defaultValue;
    // const datalevel = codComune ? DataLevel.COMUNE : codProvincia ? DataLevel.PROVINCIA : DataLevel.REGIONE;

    const codes: ResultCodes = {
      codCirc: codCircoscrizione,
      codCom: codComune,
      codProv: codProvincia,
      codReg: codRegione,
    };

    setDataLevel(DataLevel.CIRCOSCRIZIONE);
    setSearchCodes(codes);
  }, []);

  const mainResultMapSettings = React.useMemo(
    () => getMapSettings(DataLevel.MAIN),
    []
  );

  const mapSettings = React.useMemo(() => {
    let settings = getMapSettings(dataLevel);

    if (
      lastResult?.level === DataLevel.MAIN ||
      lastResult?.level === DataLevel.CIRCOSCRIZIONE
    ) {
      settings = {
        ...settings,
        zoom: mainResultMapSettings.zoom,
      };
    }

    return settings;
  }, [dataLevel, lastResult, mainResultMapSettings]);

  const filters = FILTERS.filter(f => {
    // hide regione and national level
    return true; // f.value > DataLevel.REGIONE;
  });

  // Function used when pressing an option within searched results
  const onOptionPress = async (value: AutocompleteOption) => {
    const codes: ResultCodes = {
      codCirc: value.cod_circ,
      codCom: value.cod_comune,
      codProv: value.cod_provincia,
      codReg: value.cod_regione,
    };

    // set search codes, wait for region to be toggled (if needed), and activate useEffect defined above
    setSearchCodes(codes);
  };

  const inputOptions = getAutocompleteOptions();

  if (!selectedResults.length) {
    return null;
  }

  return (
    <>
      <MainComponent
        inputOptions={inputOptions}
        onOptionPress={onOptionPress}
        mapSettings={mapSettings}
        filters={filters}
      />
    </>
  );
};
