import * as React from 'react';
import { VariableSizeList } from 'react-window';

export const usePrevious = <T>(value: T, initialValue?: T) => {
  const ref = React.useRef<T>(initialValue as T);
  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};
