import { formatNumber } from '@/lib/helpers';
import {
  Candidate,
  Coalition,
  DataLevel,
  DynamicTurnout,
  Result,
  Year,
} from '@/lib/model';
import { formatCapitalize } from '@/utils/format-capitalize.util';
import { formatDecimal } from '@/utils/format-decimal.util';
import { getTournoutValue } from '@/utils/get-turnout-value.utils';
import * as React from 'react';
import './index.scss';

export interface PopupProps {
  x: number;
  y: number;
  offset: number;
  data: Result;
  selectedCandidate: Candidate | null;
  selectedYear: Year;
  isTurnoutOpen: boolean;

  /*
   *  Per il calcolo dell'affluenza dinamica
   */
  dynamicTurnout?: DynamicTurnout[];
  dataLevel?: DataLevel;
  lastResult?: Result;
}

export const Popup: React.FC<PopupProps> = ({
  data,
  x,
  y,
  offset,
  selectedCandidate,
  selectedYear,
  isTurnoutOpen,
  dataLevel,
  dynamicTurnout,
  lastResult,
}) => {
  let turnoutValue: any;

  if (dataLevel && dynamicTurnout && lastResult) {
    turnoutValue = getTournoutValue(
      dataLevel,
      dynamicTurnout as DynamicTurnout[],
      data as Result
    );
  }

  return (
    <div
      className="Popup"
      style={{
        top:
          y > 350
            ? y - (250 + (data?.coalitions?.length || 0) * 16)
            : y + offset,
        left: x > 265 ? x - 325 : x + offset,
        position: 'sticky',
      }}
    >
      <div className="Popup__title">
        <span>{formatCapitalize(data.placeName)}</span>
      </div>
      <div className="Popup__bar">
        {data?.coalitions?.map((coalition, i) => (
          <div
            style={{
              backgroundColor: coalition.fillColorPopup,
              width: `${coalition.percentage}%`,
            }}
            key={i.toString()}
          />
        ))}
      </div>

      {isTurnoutOpen && (
        <div className="Popup__information" style={{ fontSize: '0.7rem' }}>
          {selectedYear === Year.Y2024 && (
            <div>
              <span>
                Sezioni pervenute: <b>{formatNumber(turnoutValue?.enti_p)}</b>{' '}
                su <b>{formatNumber(turnoutValue?.enti_t)}</b>{' '}
              </span>
            </div>
          )}
          <div>
            <span>
              {`${data.summary.turnout.label}`}:{' '}
              <b>{`${data.summary.turnout.value}`}</b>
            </span>
            <span>
              Voti: <b>{`${data.summary.totalVoters.value}`}</b>
            </span>
          </div>
        </div>
      )}

      {!isTurnoutOpen && (
        <>
          <div className="Popup__information" style={{ fontSize: '0.7rem' }}>
            {selectedYear === Year.Y2024 && (
              <div>
                <span>
                  <b>{data.scrutinized.current}</b> di{' '}
                  <b>{data.scrutinized.total}</b> sezioni scrutinate
                </span>
              </div>
            )}
            <div>
              <span>
                {`${data.summary.turnout.label}`}:{' '}
                <b>{`${data.summary.turnout.value}`}</b>
              </span>
              <span>
                Voti: <b>{`${data.summary.totalVoters.value}`}</b>
              </span>
            </div>
          </div>
          <div className="Popup__votes">
            <div className="Popup__votes__header">
              {data.showVoti && <span>Voti</span>}
              <span>%</span>
            </div>
            {data?.coalitions?.map((coalition, i) => {
              if (selectedCandidate) {
                if (coalition.coalitionId !== selectedCandidate.coalitionId) {
                  return null;
                }

                if (selectedCandidate.candidateId) {
                  coalition = coalition.candidates.find(
                    c => c.candidateId === selectedCandidate.candidateId
                  ) as Coalition;
                }

                coalition.label = selectedCandidate.label;
              }

              return (
                <div className="Popup__votes__result" key={i.toString()}>
                  <div
                    className="Popup__votes__result__bar"
                    style={{ backgroundColor: coalition.fillColorPopup }}
                  />
                  <div className="Popup__votes__result__name">
                    {selectedYear === Year.Y2024 || selectedYear === Year.Y2019
                      ? coalition.fullName || coalition.label
                      : coalition.label}
                  </div>
                  <div className="Popup__votes__result__numbers">
                    <span>{coalition.votes}</span>
                    <span>
                      {`${formatDecimal(coalition.percentage, true)}`}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
