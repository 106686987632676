import { DataLevel, LabelValue } from '@/lib/model';
import * as React from 'react';
import { Filter } from './Filter';
import './index.scss';

export interface FiltersProps {
  filters: LabelValue<DataLevel>[];
  value: DataLevel;
  onChange: (v: DataLevel) => void;
}

export const Filters: React.FC<FiltersProps> = ({
  filters,
  value,
  onChange,
}) => (
  <div className="Filters">
    {filters.map((filter, i) => (
      <Filter
        label={filter.label}
        selected={value === filter.value}
        onClick={() => onChange(filter.value)}
        key={i.toString()}
      />
    ))}
  </div>
);
