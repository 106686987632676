import { useResetCache } from '@/lib/hookHelpers';
import { AutocompleteOption } from '@/lib/model';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, {
  autocompleteClasses,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import './index.scss';

const LISTBOX_PADDING = 8; // px

const filterOptions = createFilterOptions<AutocompleteOption>({
  matchFrom: 'start',
});

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].nome_comune}
    </Typography>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export interface SearchBarProps {
  options: AutocompleteOption[];
  onOptionPress: (value: AutocompleteOption) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  options,
  onOptionPress,
}) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  const handleOpen = () => {
    if (value.length > 0) {
      setOpen(true);
    }
  };

  const onInputChange = (
    _: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
    reason: string
  ) => {
    if (reason === 'reset') {
      setValue('');
      setOpen(false);
      return;
    }

    setValue(newInputValue);
    if (newInputValue.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  // console.log( 'value', value )

  return (
    <Autocomplete<AutocompleteOption>
      onChange={(_, value) => {
        // console.log( 'RICERCA COMUNE', value )

        if (!value) {
          return;
        }

        onOptionPress(value);
        setValue('');
        onInputChange(null as any, '', 'reset');
      }}
      open={open}
      onOpen={handleOpen}
      onInputChange={onInputChange}
      inputValue={value}
      clearOnEscape
      disableListWrap
      filterOptions={filterOptions}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      getOptionLabel={option => option.nome_comune}
      options={options}
      groupBy={option => `PROVINCIA DI ${option.nome_provincia}` as string}
      renderInput={params => (
        <div ref={params.InputProps.ref} className="Input">
          <SearchIcon />
          <div className="Input__Inner">
            <input
              {...params.inputProps}
              type="text"
              placeholder="Cerca per comune"
            />
          </div>
          <span onClick={() => setValue('')}>
            <CloseIcon />
          </span>
        </div>
      )}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={params => params as unknown as React.ReactNode}
    />
  );
};
