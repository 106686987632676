import MappingCameraComuni from '@/data/mapping-camera-comuni7917.json';
import CodiciComuni from '@/data/Raccordo_Comuni_seas_istatshape_coord.json';

const comuneMap: { [key: string]: string | null } = {};

export const getCodComune = (
  codReg: string | undefined,
  codProv: string | undefined,
  codComune: string | undefined
) => {
  // remove initial 0 from SEAS
  if (codReg?.indexOf('0') === 0) {
    codReg = codReg.substring(1);
  }
  // remove initial 0 from SEAS
  if (codProv?.indexOf('0') === 0) {
    codProv = codProv.substring(1);
  }
  // remove initial 0 from SEAS
  if (codComune?.indexOf('0') === 0) {
    codComune = codComune.substring(1);
  }

  const key = `${codReg}-${codProv}-${codComune}`;
  if (Object.keys(comuneMap).length === 0) {
    MappingCameraComuni.forEach(c => {
      const key = `${c.cod_regione}-${c.cod_provincia}-${c.cod_comune}`;
      comuneMap[key] = c.PRO_COM || null;
    });
  }
  return comuneMap[key] || null;
};

export const getCoordFromProCom = (proCom: string) => {
  const comune = CodiciComuni.find(c => c.PRO_COM === proCom);
  if (comune) {
    return comune.coordinates;
  }
  return null;
};

export const getCoordFromCodes = (
  codReg: string,
  codProv: string,
  codCom: string
) => {
  const comune = MappingCameraComuni.find(
    c =>
      c.cod_regione === codReg &&
      c.cod_provincia === codProv &&
      c.cod_comune === codCom
  );
  if (comune) {
    return getCoordFromProCom(comune.PRO_COM);
  }
  return null;
};
