import { Result } from '@/lib/model';
import * as React from 'react';
import { Category } from './Category';
import './index.scss';

export interface CategoriesProps {
  results: Result[];
  onClick: (result: Result) => void;
}

export const Categories: React.FC<CategoriesProps> = ({ results, onClick }) => (
  <div className="Categories">
    {results.map((result, i) => (
      <Category
        label={result.placeName}
        selected={i === results.length - 1}
        onClick={() => onClick(result)}
        key={i.toString()}
      />
    ))}
  </div>
);
