import { Header } from '@/components/Header';
import Config from '@/lib/config';
import { useMainContext } from '@/lib/context';
import {
  AutocompleteOption,
  DataLevel,
  LabelValue,
  MapSettings,
} from '@/lib/model';
import { Backdrop, CircularProgress } from '@mui/material';
import * as React from 'react';
import { Filters } from './Filters';
import './index.scss';
import { Map } from './Map';
import { Results } from './Results';
import { SearchBar } from './SearchBar';
import { SelectYear } from './SelectYear';

export interface MainComponentProps {
  mapSettings: MapSettings;
  filters: LabelValue<DataLevel>[];
  onOptionPress: (value: AutocompleteOption) => void;
  inputOptions: AutocompleteOption[];
}

export const MainComponent: React.FC<MainComponentProps> = ({
  mapSettings,
  filters,
  onOptionPress,
  inputOptions,
}) => {
  const {
    selectedYear,
    setSelectedYear,
    dataLevel,
    setDataLevel,
    handleResult,
    mainResult,
    loadingMap,
    setLoadingMap,
  } = useMainContext();

  // console.log( 'MAIN COMPONENT', {
  //   selectedYear,
  //   setSelectedYear,
  //   selectedRegion,
  //   setSelectedRegion,
  //   dataLevel,
  //   setDataLevel,
  //   results
  // })

  const onChangeDataLevel = (value: DataLevel) => {
    // Resetto tutti i dati
    if (value === DataLevel.COMUNE) {
      if (!loadingMap) {
        setLoadingMap(true);
      }
      handleResult(mainResult!, true);
    }

    setDataLevel(value);
  };

  return (
    <div className="Main">
      <Header title={Config.headerTitle} />
      <div className="Main__Content">
        <div>
          {/*<Tabs<Region>*/}
          {/*  tabs={REGIONS}*/}
          {/*  value={selectedRegion}*/}
          {/*  onTabChange={setSelectedRegion}*/}
          {/*/>*/}
          <div className="Main__Content__Filters">
            <SearchBar options={inputOptions} onOptionPress={onOptionPress} />
            <SelectYear value={selectedYear} onYearChange={setSelectedYear} />
          </div>
        </div>
        <div className="Main__Content__RightPanel">
          <Filters
            filters={filters}
            value={dataLevel}
            onChange={onChangeDataLevel}
          />
          <Map {...mapSettings} />
        </div>
        <div>
          <Results />
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loadingMap}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
