import { DataLevel, ResultAPI } from '@/lib/model';

export const getMapSettings = (level: DataLevel) => {
  switch (level) {
    case DataLevel.COMUNE:
      return {
        layerPropId: 'PRO_COM',
        sourceLayer: '2024_Comuni_Italia_20',
        zoom: 6.8,
      };

    case DataLevel.PROVINCIA:
      return {
        layerPropId: 'COD_PROV',
        sourceLayer: '2024_Province_Italia_20',
        zoom: 6.8,
      };

    case DataLevel.REGIONE:
      return {
        layerPropId: 'COD_REG',
        sourceLayer: '2024_Regioni_Italia_20',
        zoom: 6.8,
      };
    case DataLevel.CIRCOSCRIZIONE:
    case DataLevel.MAIN:
    default:
      return {
        layerPropId: 'COD_RIP',
        sourceLayer: '2024_Circoscrizioni_EU_Italia_20',
        zoom: 4.8,
      };
  }
};

export const getLevelSettings = (
  level: DataLevel
): {
  directory: string;
  variable: keyof ResultAPI['int'];
} => {
  switch (level) {
    case DataLevel.COMUNE:
      return {
        directory: 'comuni',
        variable: 'cod_com', // will be anyway overriden with PRO_COM
      };

    case DataLevel.PROVINCIA:
      return {
        directory: 'collegi',
        variable: 'cod_prov',
      };

    case DataLevel.REGIONE:
      return {
        directory: 'regioni',
        variable: 'cod_reg',
      };
    case DataLevel.CIRCOSCRIZIONE:
      return {
        directory: 'circoscrizioni',
        variable: 'cod_circ',
      };
    case DataLevel.MAIN:
    default:
      return {
        directory: '', // set in different place
        variable: 'cod_circ',
      };
  }
};
