import { Sign } from '@/components/Sign';
import { Candidate } from '@/lib/model';
import { formatDecimal } from '@/utils/format-decimal.util';
import * as React from 'react';
import './index.scss';

export interface CoalitionProps extends Candidate {
  showHeatMap?: boolean;
  onCandidateClick: () => void;
  isCandidateOpen: boolean;
}

export const Coalition: React.FC<CoalitionProps> = ({
  label,
  fullName,
  votes,
  percentage,
  fillColor,
  showHeatMap,
  isCandidateOpen,
  onCandidateClick,
}) => (
  <div className="Coalition">
    <div className="Coalition__title">
      <div>
        {fullName && (
          <span
            className={`Coalition__title__label ${
              fullName ? 'Coalition__title__label__fullName' : ''
            }`}
          >
            {fullName.replace(
              'LETIZIA MARIA BRICHETTO ARNABOLDI',
              'LETIZIA MORATTI'
            )}
          </span>
        )}
        {!fullName && (
          <span
            className={`Coalition__title__label ${
              fullName ? 'Coalition__title__label__fullName' : ''
            }`}
          >
            {' '}
            {label}
          </span>
        )}
        {fullName && (
          <span className="Coalition__title__fullName">{label}</span>
        )}
      </div>
      {showHeatMap && (
        <Sign isOpen={isCandidateOpen} onClick={() => onCandidateClick()} />
      )}
    </div>
    <div className="Coalition__data">
      <div className="Coalition__bar">
        <div style={{ backgroundColor: fillColor, width: `${percentage}%` }} />
      </div>
      <div className="Coalition__values">
        <span>{votes}</span>
        <span>{`${formatDecimal(percentage, true)}`}</span>
      </div>
    </div>
  </div>
);
