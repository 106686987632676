import * as React from 'react';
import './index.scss';
import {formatCapitalize} from "@/utils/format-capitalize.util";

export interface CategoryProps {
  label: string;
  selected?: boolean;
  onClick: () => void;
}

export const Category: React.FC<CategoryProps> = ({
  label,
  selected,
  onClick,
}) => (
  <div
    className={['Category', selected && 'selected'].join(' ')}
    onClick={!selected ? onClick : undefined}
  >
    {formatCapitalize(label)}
  </div>
);
