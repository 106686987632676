export const Clients = {
  SOLEAGI: 'soleagi',
  SKY: 'sky',
  GENERAL: 'general',
};

const Config = {
  projectName: process.env.REACT_APP_PROJECT_NAME || 'elections',
  headerTitle: process.env.REACT_APP_PROJECT_HEADER_TITLE || 'Elections',
  fontName: process.env.REACT_APP_FONT_NAME || 'Sky Text',
  client: process.env.REACT_APP_CLIENT || Clients.GENERAL,
  activeRegions: process.env.REACT_APP_ACTIVE_REGIONS || 'all',
  publicUrl: process.env.REACT_APP_PUBLIC_URL || '',
  mapboxStyle: process.env.REACT_APP_MAPBOX_STYLE || '',
  mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN || '',
  realtimeApiUrl: process.env.REALTIME_API_URL || '',
  lastVersionApiUrl: process.env.LAST_VERSION_API_URL || ''
};

export default Config;
