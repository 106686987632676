import { Coalition } from '@/components/Coalition';
import { CoalitionsHeader } from '@/components/CoalitionsHeader';
import { Candidate, Coalition as CoalitionType } from '@/lib/model';
import * as React from 'react';
import './index.scss';

export interface CoalitionProps {
  data: CoalitionType[];
  selectedCandidate: Candidate | null;
  onCandidateClick: (candidate: Candidate) => void;
}

export const Coalitions: React.FC<CoalitionProps> = ({
  data,
  selectedCandidate,
  onCandidateClick,
}) => (
  <div className="Coalitions">
    <CoalitionsHeader />
    {data?.map((coalition, i) => (
      <React.Fragment key={i.toString()}>
        {i > 0 && (
          <div
            style={{ borderTop: '0.5px solid #000000', paddingTop: '1em' }}
          ></div>
        )}

        <Coalition
          {...coalition}
          isCandidateOpen={
            selectedCandidate?.coalitionId === coalition.coalitionId
          }
          showHeatMap
          onCandidateClick={() => onCandidateClick(coalition)}
        />
        <div className="Coalitions__Candidates">

          {coalition.candidates.map((candidate, k) => (
            <Coalition
              {...candidate}
              showHeatMap
              isCandidateOpen={
                selectedCandidate?.candidateId === candidate.candidateId
              }
              onCandidateClick={() => onCandidateClick(candidate)}
              key={`${i}_${k}`}
            />
          ))}
        </div>
      </React.Fragment>
    ))}
  </div>
);
