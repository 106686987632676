import { Map as MBMap } from '@/components/Map';
import { useMainContext } from '@/lib/context';
import { getCoordFromCodes } from '@/lib/convertIdComune';
import { YEARS } from '@/lib/fixedTypes';
import { DataLevel, MapSettings, Result } from '@/lib/model';
import { hexToRgb } from '@/utils/hex-to-rgb.utils';
import center from '@turf/center';
import { AllGeoJSON } from '@turf/helpers';
import { MapboxEvent, MapLayerMouseEvent } from 'mapbox-gl';
import * as React from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import './index.scss';
import { dataLayer as DATALAYER } from './map-style';
import { Popup } from './Popup';

// const LISTA_COMUNI = require('./eu_com_2019.json');

// Default values
// const sourceId = 'aurelianv.Mappa_Reg_LazioLombardia_tileset';
// const sourceUrl = 'mapbox://aurelianv.Mappa_Reg_LazioLombardia_tileset';

/**
 * Nuovo layer con tutti i comuni italiani
 */
const SOURCE_ID_MAP_ALL = 'aurelianv.Mappa_Europee_20_tileset';
const SOURCE_URL_MAP_ALL = 'mapbox://aurelianv.Mappa_Europee_20_tileset';

export const Map: React.FC<MapSettings> = ({
  layerPropId,
  sourceLayer,
  zoom,
}) => {
  const {
    setResults,
    results,
    handleResult,
    dataLevel,
    lastResult,
    selectedCandidate,
    selectedResults,
    selectedYear,
    isTurnoutOpen,
    dynamicTurnout,
    loadingMap,
    setLoadingMap,
    forceCoordinates,
  } = useMainContext();

  const [cursor, setCursor] = React.useState<string>('auto');
  const [point, setPoint] = React.useState({ x: 0, y: 0 });
  const [popupResult, setPopupResult] = React.useState<Result | null>(null);
  const { electionsMap: map } = useMap();
  const [isCoordinateSet, setIsCoordinateSet] = React.useState(false);

  const [dataLayer4, setDataLayer4] = React.useState(DATALAYER);
  const [dataLayer3, setDataLayer3] = React.useState(DATALAYER);
  const [dataLayer2, setDataLayer2] = React.useState(DATALAYER);
  const [dataLayer1, setDataLayer1] = React.useState(DATALAYER);
  const [dataLayer, setDataLayer] = React.useState(DATALAYER);
  const [dataLayerProvince, setDataLayerProvince] = React.useState(DATALAYER);
  const [dataLayerCircoscrizioni, setDataLayerCircoscrizioni] =
    React.useState(DATALAYER);
  const [dataLayerRegioni, setDataLayerRegioni] = React.useState(DATALAYER);

  //Timeout per il debouncetime
  const [timeoutFetchMunicipalities, setTimeoutFetchMunicipalities] =
    React.useState(
      null as null | { region: string; province: string; timeout: any }
    );

  // const initialResByCircResults: [string, Result[]][] = [
  //   ["1", []],["2", []],["3", []],["4", []],["5", []]];
  // const resByCircMap = new globalThis.Map<string, Result[]>(initialResByCircResults);
  const layersMap = new globalThis.Map<string, Result>();
  results.forEach((el: any) => {
    layersMap.set(el.mapLayer.layerId + '', el);
    // if (dataLevel === DataLevel.COMUNE && (selectedYear === Year.Y2019 || selectedYear === Year.Y2022)) {
    //   resByCircMap.get(el.mapLayer.layerId)?.push(el);
    // }
  });

  // PARSE DEGLI 8000 COMUNI
  // const final_LISTA_COMUNI = LISTA_COMUNI.map((item: any) => {
  //   // Destructure the properties needed from the "int" object
  //   const { int: { cod_circ_eu, cod_reg, cod_prov, cod_com, ele_t, vot_t, perc_vot, sk_bianche, sk_nulle }, cand } = item;

  //   // Sort the "cand" array by the property "voti_uni" in descending order
  //   const sortedCand = cand.sort((a: any, b: any) => b.voti_uni - a.voti_uni);

  //   // Return a new object with only the needed properties and sorted "cand" array
  //   return {
  //     int: { cod_circ_eu, cod_reg, cod_prov, cod_com, ele_t, vot_t, perc_vot, sk_bianche, sk_nulle },
  //     cand: sortedCand.map((candidate: any) => {
  //       // Destructure the properties needed from the "cand" array elements
  //       const { pos, voti_uni, perc_uni, liste } = candidate;

  //       // Sort the "liste" array by the property "voti" in descending order
  //       const sortedListe = liste.sort((a: any, b: any) => b.voti - a.voti)
  //                               .map(({ pos, voti, perc }: { pos: number, voti: number, perc: string }) => ({ pos, voti, perc }));

  //       // Return a new object with only the needed properties and sorted "liste" array
  //       return {
  //         pos,
  //         voti_uni,
  //         perc_uni,
  //         liste: sortedListe
  //       };
  //     })
  //   };
  // });
  // console.log(final_LISTA_COMUNI);

  React.useEffect(() => {
    const goTo = ([lng, lat]: number[]) => {
      // override for Basilicata
      // lat = 42.2429436;
      // lng = 13.923352;
      map?.setCenter({
        lat,
        lng,
      });
    };

    if ((forceCoordinates?.length || 0) > 0) goTo(forceCoordinates!);
  }, [forceCoordinates]);

  React.useEffect(() => {
    const { coordinates } = lastResult?.mapLayer;
    const isItSet = !!coordinates.length;
    setIsCoordinateSet(isItSet);
  }, [lastResult]);

  React.useEffect(() => {
    // set map center to given coordinates
    const goTo = ([lng, lat]: number[]) => {
      // override for Basilicata
      // lat = 42.2429436;
      // lng = 13.923352;
      map?.setCenter({
        lat,
        lng,
      });
    };

    const onLayerLoad = () => {
      const layerId = `${lastResult.mapLayer.layerId}`;

      if (dataLevel === DataLevel.COMUNE) {
        const coord = getCoordFromCodes(
          lastResult.idCodes.codReg!,
          lastResult.idCodes.codProv!,
          lastResult.idCodes.codCom!
        );
        if (coord) {
          goTo(coord!);
          map?.setZoom(10);
        }
      }

      // check if layer has rendered before proceeding
      const isLayerRendered = map?.getLayer(layerId);
      if (!isLayerRendered) {
        return;
      }

      let layers = map?.queryRenderedFeatures(undefined, {
        layers: [layerId],
      });

      if (layers?.length === 0) {
        // Juan please check this
        let layersAll = map?.querySourceFeatures('composite', {
          //sourceLayer: '25_Comuni_5',
          sourceLayer: '2024_Comuni_Italia_20',
        });
        layers = layersAll?.filter(l => l.id === +layerId);
      }

      if (!layers?.length) {
        return;
      }
      const { coordinates } = center(layers[0] as AllGeoJSON).geometry;

      // go to coordinate
      goTo(coordinates);

      // setting flag to true after finding coordinate
      setIsCoordinateSet(true);
    };

    const { coordinates } = lastResult?.mapLayer;

    if (!coordinates.length) {
      // these following listeners work to understand
      // when map has fully loaded
      if (isCoordinateSet) {
        map?.getMap().off('idle', onLayerLoad);
      } else {
        map?.once('idle', onLayerLoad);
      }

      return;
    }

    setIsCoordinateSet(true);

    if (lastResult?.level === DataLevel.REGIONE) {
      // recenter only if at region level, for province and comune no center of the map on click
      goTo(coordinates);
    }
  }, [map, lastResult, isCoordinateSet, setIsCoordinateSet]);

  React.useEffect(() => {
    map?.setZoom(zoom);
  }, [map, zoom]);

  const onIdle = async (e: MapboxEvent) => {
    // console.log('onIdle lastResult', lastResult);
    // setLoadingMap(false);
    // const features1 = map?.querySourceFeatures('2024_Comuni_COD_RIP_1_Italia_20');
    // console.log('Features1:', features1);
    // const features = map?.queryRenderedFeatures(undefined, {
    //   layers: ['data-1']
    //   // dataLevel === DataLevel.COMUNE
    //   // ? ['data', 'data-1', 'data-2', 'data-3', 'data-4']
    //   // : ['data']
    // });
    // console.log('Features:', features);
    // let layers = features?.filter(l => l.id === +lastResult.mapLayer?.layerId);
    // let layers = map?.queryRenderedFeatures(undefined, {
    //   layers: [lastResult.mapLayer?.layerId],
    // });
    // if (layers?.length === 0) {
    //   // Juan please check this
    //   let layersAll = map?.querySourceFeatures('composite', {
    //     //sourceLayer: '25_Comuni_5',
    //     sourceLayer: '2024_Comuni_COD_RIP_1_Italia_20',
    //   });
    //   layers = layersAll?.filter(l => l.id === +lastResult.mapLayer?.layerId);
    // }
    // if (!layers?.length) {
    //   return;
    // }
    // if (layers && layers.length > 0) {
    //   const { coordinates } = center(layers[0] as AllGeoJSON).geometry;
    //   console.log('coordinates', coordinates);
    //   if (coordinates && coordinates.length === 2)
    //   map?.setCenter({
    //     lat: coordinates[1],
    //     lng: coordinates[0],
    //   });
    // }
  };

  /**
   * Show popup on mouse move
   * @param e
   */
  const onMouseMove = async (e: MapLayerMouseEvent) => {
    // console.log('onMouseMove', e.features);
    const feature = e.features?.length && e.features[0];
    if (!feature) {
      // close popup
      setPopupResult(null);
      return;
    }

    if (point.x === e.point.x && point.y === e.point.y) {
      // console.warn('Map ::: onMouseMove ::: le coordinate non sono cambiate');
      return;
    }

    // AURE: DA RIMETTERE FORSE
    // const mapZoom = map?.getZoom() || 0;

    // if (dataLevel === DataLevel.COMUNE && mapZoom < 6) {
    //   return;
    // }

    const layerId = feature.id + '';
    const result = (layersMap.get(layerId) || null) as Result;

    //Controllo se viene effettuata una chiamata con gli stessi dati
    if (
      result?.idCodes?.codReg === timeoutFetchMunicipalities?.region &&
      result?.idCodes?.codProv === timeoutFetchMunicipalities?.province
    ) {
      // console.log('chiamata in corso...');
      // open popup with info
      setPopupResult(result);
      return;
    }

    if (result.coalitions) {
      setPoint(e.point);
      setPopupResult(result);
      return;
    }

    // if (timeoutFetchMunicipalities?.timeout) {
    //   clearTimeout(timeoutFetchMunicipalities.timeout);
    // }

    // AURE: RIMOSSO PER ORA; NON CREDO SERVA, DOVREMMO AVERE GIA TUTTI I DATI
    // const timeout = setTimeout(async () => {
    //   // position popup
    //   setPoint(e.point);

    //   // TODO la chiamata deve essere fatta su REG-PROV viene restituita tutta la lista dei comuni, man mano che l'utente si sposta sulle province prendo carico altri comuni
    //   // richiamare questo FILE https://2023-real-time-data.s3.eu-central-1.amazonaws.com/2022/0703057.json

    //   try {
    //     const resx = await getMunicipalities(
    //       result.idCodes.codReg!,
    //       result.idCodes.codProv!,
    //       Year.Y2022,
    //       dataLevel,
    //       dynamicTurnout
    //     );

    //     const { results: newResults, found: popoupInfo } = mergeToResults(
    //       results,
    //       resx,
    //       result
    //     );
    //     setResults(newResults);

    //     // open popup with info
    //     setPopupResult(popoupInfo);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }, 1000);

    // const newTimeout = {
    //   timeout: timeout as any,
    //   region: result.idCodes.codReg!,
    //   province: result.idCodes.codProv!,
    // };

    // setTimeoutFetchMunicipalities(newTimeout);
  };

  // return layer when clicked with current coordinates
  const onClick = async (e: MapLayerMouseEvent) => {
    // avoid clicking on layer if in inner selected candidate
    if (selectedCandidate) {
      return;
    }

    const feature = e.features?.length && e.features[0];
    // I set popupResult again, to avoid the first tap not working on Android
    let popResult;
    if (feature) {
      const layerId = feature.id + '';
      popResult = (layersMap.get(layerId) || null) as Result;
      setPopupResult(popResult);
    }
    if (!popResult || !feature) {
      return;
    }

    //Controllo se il livello e' provincia allora avvio lo spinner
    if (dataLevel === DataLevel.PROVINCIA) {
      setLoadingMap(true);
    }

    // zoom and center to new coordinates
    const { coordinates: newCoordinates } = center(
      feature as AllGeoJSON
    ).geometry;

    while (Math.abs(e.lngLat.lng - newCoordinates[0]) > 180) {
      newCoordinates[0] += e.lngLat.lng > newCoordinates[0] ? 360 : -360;
    }

    // add result to left pannel

    handleResult(
      {
        ...popResult,
        mapLayer: {
          ...popResult.mapLayer,
          coordinates: newCoordinates,
        },
      },
      false
    );

    // raise "electio:navigate" event for sole
    const selectedYearObj = YEARS.find(y => y.value === selectedYear);
    let nomeProvincia = selectedResults[1]?.placeName || '';
    let nomeComune = selectedResults[2]?.placeName || '';

    if (popResult.level === DataLevel.PROVINCIA) {
      nomeProvincia = popResult.placeName;
    } else if (popResult.level === DataLevel.COMUNE) {
      nomeComune = popResult.placeName;
    }

    const event = new CustomEvent('electio:navigate', {
      detail: {
        nomeRegione: selectedResults[0]?.placeName || '',
        nomeProvincia,
        nomeComune,
        anno: selectedYearObj?.label,
      },
    });

    document.dispatchEvent(event);
  };

  // set cursor styles
  const onMouseEnter = () => {
    setCursor('pointer');
  };

  const onMouseLeave = () => {
    setCursor('auto');
    // close popup
    setPopupResult(null);
  };

  const areResultsValid = results.length > 0 && results[0].level === dataLevel;

  //
  //
  //

  React.useEffect(() => {
    // TODO DEFAULT COORDINATES
    if (lastResult?.mapLayer.coordinates?.length === 0) {
      lastResult.mapLayer.coordinates = [16.2255482, 40.4031845];
    }
  }, []);

  function resetMap() {
    (dataLayerCircoscrizioni as any).paint['fill-color'].stops = [];
    (dataLayerRegioni as any).paint['fill-color'].stops = [];
    (dataLayerProvince as any).paint['fill-color'].stops = [];
    (dataLayer as any).paint['fill-color'].stops = [];
    (dataLayer1 as any).paint['fill-color'].stops = [];
    (dataLayer2 as any).paint['fill-color'].stops = [];
    (dataLayer3 as any).paint['fill-color'].stops = [];
    (dataLayer4 as any).paint['fill-color'].stops = [];

    setDataLayerCircoscrizioni(dataLayerCircoscrizioni);
    setDataLayerRegioni(dataLayerRegioni);
    setDataLayerProvince(dataLayerProvince);
    setDataLayer(dataLayer);
    setDataLayer1(dataLayer1);
    setDataLayer2(dataLayer2);
    setDataLayer3(dataLayer3);
    setDataLayer4(dataLayer4);
  }

  function binarySearchInsert(orderedArray: any, newElement: any) {
    let left = 0;
    let right = orderedArray.length - 1;
    let mid;

    while (left <= right) {
      mid = Math.floor((left + right) / 2);
      if (orderedArray[mid][0] === newElement[0]) {
        left = mid;
        break;
      } else if (orderedArray[mid][0] < newElement[0]) {
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }
    orderedArray.splice(left, 0, newElement);
    return orderedArray;
  }

  /**
   *
   */
  React.useEffect(() => {
    //TODO si potrebbe ottimizzare facendo dei controlli sui cambiamenti

    resetMap();

    const newDataLayer: any = JSON.parse(JSON.stringify(DATALAYER));
    newDataLayer.paint['fill-color'].stops = [];

    let newDataLayer1: any;
    let newDataLayer2: any;
    let newDataLayer3: any;
    let newDataLayer4: any;

    if (dataLevel === DataLevel.COMUNE) {
      newDataLayer1 = JSON.parse(JSON.stringify(DATALAYER));
      newDataLayer1.paint['fill-color'].stops = [];
      newDataLayer2 = JSON.parse(JSON.stringify(DATALAYER));
      newDataLayer2.paint['fill-color'].stops = [];
      newDataLayer3 = JSON.parse(JSON.stringify(DATALAYER));
      newDataLayer3.paint['fill-color'].stops = [];
      newDataLayer4 = JSON.parse(JSON.stringify(DATALAYER));
      newDataLayer4.paint['fill-color'].stops = [];
    }

    switch (dataLevel) {
      case DataLevel.CIRCOSCRIZIONE:
        setDataLayerCircoscrizioni(newDataLayer);
        break;
      case DataLevel.REGIONE:
        setDataLayerRegioni(newDataLayer);
        break;
      case DataLevel.PROVINCIA:
        setDataLayerProvince(newDataLayer);
        break;
      case DataLevel.COMUNE:
        setDataLayer(newDataLayer);
        setDataLayer1(newDataLayer1);
        setDataLayer2(newDataLayer2);
        setDataLayer3(newDataLayer3);
        setDataLayer4(newDataLayer4);
        break;
    }

    switch (dataLevel) {
      case DataLevel.CIRCOSCRIZIONE:
        newDataLayer.paint['fill-color'].property = 'COD_RIP';
        newDataLayer['source-layer'] = '2024_Circoscrizioni_EU_Italia_20';
        break;
      case DataLevel.REGIONE:
        newDataLayer.paint['fill-color'].property = 'COD_REG';
        newDataLayer['source-layer'] = '2024_Regioni_Italia_20';
        break;
      case DataLevel.PROVINCIA:
        newDataLayer.paint['fill-color'].property = 'COD_PROV';
        newDataLayer['source-layer'] = '2024_Province_Italia_20';
        break;
      case DataLevel.COMUNE:
        newDataLayer.paint['fill-color'].property = 'PRO_COM';
        newDataLayer['source-layer'] = '2024_Comuni_COD_RIP_1_Italia_20';
        newDataLayer.minzoom = 4;
        newDataLayer.maxzoom = 12;
        newDataLayer1.id = 'data-1';
        newDataLayer1.paint['fill-color'].property = 'PRO_COM';
        newDataLayer1.minzoom = 4;
        newDataLayer1.maxzoom = 12;
        newDataLayer1['source-layer'] = '2024_Comuni_COD_RIP_2_Italia_20';
        newDataLayer2.id = 'data-2';
        newDataLayer2.minzoom = 4;
        newDataLayer2.maxzoom = 12;
        newDataLayer2.paint['fill-color'].property = 'PRO_COM';
        newDataLayer2['source-layer'] = '2024_Comuni_COD_RIP_3_Italia_20';
        newDataLayer3.id = 'data-3';
        newDataLayer3.minzoom = 4;
        newDataLayer3.maxzoom = 12;
        newDataLayer3.paint['fill-color'].property = 'PRO_COM';
        newDataLayer3['source-layer'] = '2024_Comuni_COD_RIP_4_Italia_20';
        newDataLayer4.id = 'data-4';
        newDataLayer4.minzoom = 4;
        newDataLayer4.maxzoom = 12;
        newDataLayer4.paint['fill-color'].property = 'PRO_COM';
        newDataLayer4['source-layer'] = '2024_Comuni_COD_RIP_5_Italia_20';

        // newDataLayer.id = 'data';
        // newDataLayer.minzoom = 4;
        // newDataLayer.maxzoom = 12;
        // newDataLayer.paint['fill-color'].property = 'PRO_COM';
        // newDataLayer['source-layer'] = '2024_Comuni_Italia_20';
        break;
    }

    // const parallelize = async ( array: any, offset:number, rows: number ) => {
    //     return new Promise((resolve, reject) => {
    //       for (let i = offset; i <= offset + rows; i++) {
    //         console.log( i , offset, rows )
    //         if (i >= array.length) {
    //           debugger;
    //           console.warn('Map::useEffect::parallelize::break')
    //           break;
    //         }
    //         const result = array[i];
    //
    //         if(!result ) {
    //           debugger;
    //           console.warn('Map::useEffect::parallelize::break2')
    //           break;
    //         }
    //
    //         const isSelected =
    //             (lastResult?.level === DataLevel.CIRCOSCRIZIONE &&
    //                 lastResult.idCodes.codCirc === result.idCodes.codCirc) ||
    //             (lastResult?.level === DataLevel.REGIONE &&
    //                 lastResult.idCodes.codReg === result.idCodes.codReg) ||
    //             (lastResult?.level === DataLevel.PROVINCIA &&
    //                 lastResult.idCodes.codReg === result.idCodes.codReg &&
    //                 lastResult.idCodes.codProv === result.idCodes.codProv) ||
    //             ((lastResult?.level === DataLevel.COMUNE ||
    //                     result.level === DataLevel.COMUNE) &&
    //                 lastResult.idCodes.codReg === result.idCodes.codReg &&
    //                 lastResult.idCodes.codProv === result.idCodes.codProv &&
    //                 lastResult.idCodes.codCom === result.idCodes.codCom);
    //
    //         const opacity =
    //             !selectedCandidate && !isTurnoutOpen
    //                 ? isSelected
    //                     ? 1
    //                     : 0.6
    //                 : result.mapLayer.opacity;
    //
    //         const dataLayerId = +result.mapLayer.layerId;
    //         const newColor = hexToRgb(result.mapLayer.fillColor, opacity);
    //         if (
    //             dataLevel !== DataLevel.COMUNE ||
    //             ('1' === result.idCodes?.codCirc && dataLevel === DataLevel.COMUNE)
    //         ) {
    //           binarySearchInsert(newDataLayer.paint['fill-color'].stops, [dataLayerId, newColor]);
    //           //newDataLayer.paint['fill-color'].stops.push([dataLayerId, newColor]);
    //         }
    //         if (dataLevel == DataLevel.COMUNE) {
    //           if ('2' === result.idCodes?.codCirc) {
    //             binarySearchInsert(newDataLayer1.paint['fill-color'].stops, [dataLayerId, newColor]);
    //             //newDataLayer1.paint['fill-color'].stops.push([dataLayerId, newColor]);
    //           }
    //           if ('3' === result.idCodes?.codCirc) {
    //             binarySearchInsert(newDataLayer2.paint['fill-color'].stops, [dataLayerId, newColor]);
    //             //newDataLayer2.paint['fill-color'].stops.push([dataLayerId, newColor]);
    //           }
    //           if ('4' === result.idCodes?.codCirc) {
    //             binarySearchInsert(newDataLayer3.paint['fill-color'].stops, [dataLayerId, newColor]);
    //             //newDataLayer3.paint['fill-color'].stops.push([dataLayerId, newColor]);
    //           }
    //           if ('5' === result.idCodes?.codCirc) {
    //             binarySearchInsert(newDataLayer4.paint['fill-color'].stops, [dataLayerId, newColor]);
    //             //newDataLayer4.paint['fill-color'].stops.push([dataLayerId, newColor]);
    //           }
    //         }
    //         resolve(true);
    //       }
    //     });
    // }

    results.forEach(result => {
      if (isNaN(+result.mapLayer.layerId)) return;

      let codRegCurr = +result!.idCodes!.codReg!;
      let codRegLast = +lastResult!.idCodes!.codReg!;
      let codProvCurr = +result!.idCodes!.codProv!;
      let codProvLast = +lastResult!.idCodes!.codProv!;

      const isSelectedCirc =
        lastResult?.level === DataLevel.CIRCOSCRIZIONE &&
        lastResult.idCodes.codCirc === result.idCodes.codCirc;
      const isSelectedReg =
        lastResult?.level === DataLevel.REGIONE && codRegLast === codRegCurr;
      const isSelectedProv =
        lastResult?.level === DataLevel.PROVINCIA &&
        codRegLast === codRegCurr &&
        codProvLast === codProvCurr;
      const isSelectedCom =
        (lastResult?.level === DataLevel.COMUNE ||
          result.level === DataLevel.COMUNE) &&
        lastResult.idCodes.codReg === result.idCodes.codReg &&
        lastResult.idCodes.codProv === result.idCodes.codProv &&
        lastResult.idCodes.codCom === result.idCodes.codCom;

      const isSelected =
        isSelectedCirc || isSelectedReg || isSelectedProv || isSelectedCom;

      const opacity =
        !selectedCandidate && !isTurnoutOpen
          ? isSelected
            ? 1
            : 0.3
          : result.mapLayer.opacity;

      const dataLayerId = +result.mapLayer.layerId;
      const newColor = hexToRgb(result.mapLayer.fillColor, opacity);

      // circoscrizione 1 -> regioni 1,2,3,7
      // circoscrizione 2 -> regioni 4,5,6,8
      // circoscrizione 3 -> regioni 9,10,11,12
      // circoscrizione 4 -> regioni 13,14,15,16,17,18
      // circoscrizione 5 -> regioni 19,20
      let codCirc;

      if (
        result.idCodes.codReg === '1' ||
        result.idCodes.codReg === '2' ||
        result.idCodes.codReg === '3' ||
        result.idCodes.codReg === '7'
      ) {
        codCirc = '1';
      } else if (
        result.idCodes.codReg === '4' ||
        result.idCodes.codReg === '5' ||
        result.idCodes.codReg === '6' ||
        result.idCodes.codReg === '8'
      ) {
        codCirc = '2';
      } else if (
        result.idCodes.codReg === '9' ||
        result.idCodes.codReg === '10' ||
        result.idCodes.codReg === '11' ||
        result.idCodes.codReg === '12'
      ) {
        codCirc = '3';
      } else if (
        result.idCodes.codReg === '13' ||
        result.idCodes.codReg === '14' ||
        result.idCodes.codReg === '15' ||
        result.idCodes.codReg === '16' ||
        result.idCodes.codReg === '17' ||
        result.idCodes.codReg === '18'
      ) {
        codCirc = '4';
      } else if (
        result.idCodes.codReg === '19' ||
        result.idCodes.codReg === '20'
      ) {
        codCirc = '5';
      }

      if (
        dataLevel !== DataLevel.COMUNE ||
        ('1' === codCirc && dataLevel === DataLevel.COMUNE)
      ) {
        binarySearchInsert(newDataLayer.paint['fill-color'].stops, [
          dataLayerId,
          newColor,
        ]);
        //newDataLayer.paint['fill-color'].stops.push([dataLayerId, newColor]);
      }
      if (dataLevel == DataLevel.COMUNE) {
        if ('2' === codCirc) {
          binarySearchInsert(newDataLayer1.paint['fill-color'].stops, [
            dataLayerId,
            newColor,
          ]);
          //newDataLayer1.paint['fill-color'].stops.push([dataLayerId, newColor]);
        }
        if ('3' === codCirc) {
          binarySearchInsert(newDataLayer2.paint['fill-color'].stops, [
            dataLayerId,
            newColor,
          ]);
          //newDataLayer2.paint['fill-color'].stops.push([dataLayerId, newColor]);
        }
        if ('4' === codCirc) {
          binarySearchInsert(newDataLayer3.paint['fill-color'].stops, [
            dataLayerId,
            newColor,
          ]);
          //newDataLayer3.paint['fill-color'].stops.push([dataLayerId, newColor]);
        }
        if ('5' === codCirc) {
          binarySearchInsert(newDataLayer4.paint['fill-color'].stops, [
            dataLayerId,
            newColor,
          ]);
          //newDataLayer4.paint['fill-color'].stops.push([dataLayerId, newColor]);
        }
      }
    });

    const length = Math.floor(results.length / 2);

    // Promise.all(
    //     [
    //       parallelize( results, 0, length ),
    //       parallelize( results, length+1, results.length )
    //     ]
    // ).then( () => {
    //   //TODO DA ELIMINARE
    if (
      dataLevel === DataLevel.COMUNE &&
      newDataLayer.paint['fill-color'].stops.findIndex(
        (tuple: any) => tuple[0] === 5122
      ) === -1
    ) {
      const newData = [
        [5122, '#1F8CCB'],
        [25075, '#1F8CCB'],
        [28108, '#1F8CCB'],
        [24128, '#1F8CCB'],
        [13256, '#1F8CCB'],
        [12144, '#1F8CCB'],
      ];
      newDataLayer.paint['fill-color'].stops.concat(newData);
      newDataLayer1.paint['fill-color'].stops.concat(newData);
      newDataLayer2.paint['fill-color'].stops.concat(newData);
      newDataLayer3.paint['fill-color'].stops.concat(newData);
      newDataLayer4.paint['fill-color'].stops.concat(newData);
    }

    // newDataLayer.paint['fill-color'].stops.sort(
    //   (a: any, b: any) => a[0] - b[0]
    // );
    // if (dataLevel == DataLevel.COMUNE) {
    //   newDataLayer1.paint['fill-color'].stops.sort(
    //     (a: any, b: any) => a[0] - b[0]
    //   );
    //   newDataLayer2.paint['fill-color'].stops.sort(
    //     (a: any, b: any) => a[0] - b[0]
    //   );
    //   newDataLayer3.paint['fill-color'].stops.sort(
    //     (a: any, b: any) => a[0] - b[0]
    //   );
    //   newDataLayer4.paint['fill-color'].stops.sort(
    //     (a: any, b: any) => a[0] - b[0]
    //   );
    // }

    switch (dataLevel) {
      case DataLevel.CIRCOSCRIZIONE:
        setDataLayerCircoscrizioni(newDataLayer);
        break;
      case DataLevel.REGIONE:
        setDataLayerRegioni(newDataLayer);
        break;
      case DataLevel.PROVINCIA:
        setDataLayerProvince(newDataLayer);
        break;
      case DataLevel.COMUNE:
        setDataLayer(newDataLayer);
        setDataLayer1(newDataLayer1);
        setDataLayer2(newDataLayer2);
        setDataLayer3(newDataLayer3);
        setDataLayer4(newDataLayer4);
        break;
    }

    // @ts-ignore
    clearTimeout(window['mapLoader']);

    // @ts-ignore
    window['mapLoader'] = setTimeout(() => {
      setLoadingMap(false);
    }, 1500);
    //})
  }, [results, lastResult]); //FIXME lastResult serve per il click dalla provincia al comune, ma genera diversi render

  function simulateClick(coordinates: [number, number]) {}

  if (
    dataLevel === DataLevel.CIRCOSCRIZIONE &&
    lastResult?.mapLayer.coordinates?.length === 0
  ) {
    lastResult.mapLayer.coordinates = [12.3021825, 42.1946976]; //Per centrare l'italia
  }

  let showCircoscrizione = false;
  let showRegione = false;
  let showProvincia = false;
  let showComune = false;
  let showComune1 = false;
  let showComune2 = false;
  let showComune3 = false;
  let showComune4 = false;
  if (areResultsValid) {
    if (dataLevel === DataLevel.CIRCOSCRIZIONE) {
      showCircoscrizione =
        (dataLayerCircoscrizioni as any).paint['fill-color'].stops?.length > 0;
    } else if (dataLevel === DataLevel.REGIONE) {
      showRegione =
        (dataLayerRegioni as any).paint['fill-color'].stops?.length > 0;
    } else if (dataLevel === DataLevel.PROVINCIA) {
      showProvincia =
        (dataLayerProvince as any).paint['fill-color'].stops?.length > 0;
    } else if (dataLevel === DataLevel.COMUNE) {
      showComune = (dataLayer as any).paint['fill-color'].stops?.length > 0;
      showComune1 = (dataLayer1 as any).paint['fill-color'].stops?.length > 0;
      showComune2 = (dataLayer2 as any).paint['fill-color'].stops?.length > 0;
      showComune3 = (dataLayer3 as any).paint['fill-color'].stops?.length > 0;
      showComune4 = (dataLayer4 as any).paint['fill-color'].stops?.length > 0;
    }
  }

  return (
    <div className="Map">
      <MBMap
        id="electionsMap"
        initialViewState={{
          latitude: lastResult?.mapLayer.coordinates[1],
          longitude: lastResult?.mapLayer.coordinates[0],
          zoom,
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onIdle={onIdle}
        interactiveLayerIds={
          dataLevel === DataLevel.COMUNE
            ? ['data', 'data-1', 'data-2', 'data-3', 'data-4']
            : ['data']
        }
        cursor={cursor}
        cooperativeGestures={true}
        maxPitch={0}
      >
        {/*&& (dataLayerCircoscrizioni as any).paint['fill-color'].stops?.length*/}
        <Source id={SOURCE_ID_MAP_ALL} type="vector" url={SOURCE_URL_MAP_ALL}>
          {showCircoscrizione && <Layer {...dataLayerCircoscrizioni} />}

          {showRegione && <Layer {...dataLayerRegioni} />}

          {showProvincia && <Layer {...dataLayerProvince} />}

          {showComune && <Layer {...dataLayer} />}
          {showComune1 && <Layer {...dataLayer1} />}
          {showComune2 && <Layer {...dataLayer2} />}
          {showComune3 && <Layer {...dataLayer3} />}
          {showComune4 && <Layer {...dataLayer4} />}

          {
            // {areResultsValid &&
            //   results.map((result, i) => {
            //     const mapLayerId = Number(result?.mapLayer?.layerId) ?? null;
            //     if (mapLayerId === null || isNaN(mapLayerId)) {
            //       return null;
            //     }
            //     // same source is rendered twice FIXME
            //     console.log(
            //       'Source',
            //       SOURCE_ID_MAP_ALL,
            //       SOURCE_URL_MAP_ALL,
            //       mapLayerId
            //     );
            //     const isSelected =
            //       lastResult?.mapLayer.layerId === result.mapLayer.layerId ||
            //       lastResult?.level === DataLevel.MAIN ||
            //       lastResult?.level === DataLevel.CIRCOSCRIZIONE;
            //     const opacity =
            //       !selectedCandidate && !isTurnoutOpen
            //         ? isSelected
            //           ? 1
            //           : 0.6
            //         : result.mapLayer.opacity;
            //     const layerProperties: LayerProps = {
            //       id: `${result.mapLayer.layerId}`,
            //       'source-layer': sourceLayer,
            //       minzoom: 6, // to improve speed loading
            //       maxzoom: 10, // to improve speed loading
            //       type: 'fill',
            //       filter: ['==', layerPropId, mapLayerId],
            //       paint: {
            //         'fill-color': result.mapLayer.fillColor,
            //         'fill-opacity': opacity,
            //         'fill-outline-color': 'rgba(0, 0, 0, 0.3)',
            //       },
            //       beforeId: 'waterway-shadow',
            //     };
            //     return (
            //       <Layer
            //         {...layerProperties}
            //         key={`${layerPropId}_${result.mapLayer.layerId}_${i}`}
            //       />
            //     );
            //   })}
          }
        </Source>
        {popupResult && (
          <Popup
            data={popupResult}
            x={point.x || 0}
            y={point.y || 0}
            offset={15}
            selectedCandidate={selectedCandidate}
            selectedYear={selectedYear}
            isTurnoutOpen={isTurnoutOpen}
            dynamicTurnout={dynamicTurnout}
            dataLevel={dataLevel}
            lastResult={lastResult}
          />
        )}
      </MBMap>
    </div>
  );
};
