import { Sign } from '@/components/Sign';
import Config from '@/lib/config';
import { useMainContext } from '@/lib/context';
import { formatNumber } from '@/lib/helpers';
import {
  DataLevel,
  DynamicTurnout,
  Result,
  Summary as SummaryType,
} from '@/lib/model';
import { getTournoutValue } from '@/utils/get-turnout-value.utils';
import * as React from 'react';
import './index.scss';

export interface SummaryProps {
  data: SummaryType;
  isSignOpen?: boolean;
  onSignClick?: () => void;
  /**
   * Viene utilizzato per visualizzare la barra di avanzamento sulla parte superiore del componente
   */
  showBar?: boolean;

  dataLevel?: DataLevel;
  dynamicTurnout?: DynamicTurnout[];
  lastResult?: Result;

  /*
   * Chiavi che non devono essere visualizzate
   */
  blackList?: string[];
  category?: any;
}

export const Summary: React.FC<SummaryProps> = ({
  data,
  isSignOpen,
  onSignClick,
  showBar,
  dataLevel,
  dynamicTurnout,
  lastResult,
  blackList,
  category,
}) => {
  const { soloAffluenza2024, setSoloAffluenza2024 } = useMainContext();

  let turnoutValue: any;

  if (category) {
    turnoutValue = getTournoutValue(
      category.level,
      dynamicTurnout as DynamicTurnout[],
      lastResult as Result
    );
  }

  // Serve per nascondere il componente Live quando si tratta di solo affluenza per il 2024
  let comAtt = turnoutValue?.comunicazioneAttiva ?? 0;
  if (comAtt === 4 && soloAffluenza2024) {
    setSoloAffluenza2024(false);
  }

  return (
    <>
      {showBar && (
        <>
          <div className="Summary__header">
            <span>
              Sezioni pervenute:
              <br />
              <b>{formatNumber(turnoutValue?.enti_p)}</b> su{' '}
              <b>{formatNumber(turnoutValue?.enti_t)}</b>
            </span>

            {(comAtt ?? 0) > 0 && (
              <div className="Summary__header__button">
                <img
                  src={`${Config.publicUrl}/assets/liveIcon.svg`}
                  alt="Live Results"
                />
                {comAtt === 1 && <span>ORE 23</span>}
                {comAtt === 2 && <span>ORE 12</span>}
                {comAtt === 3 && <span>ORE 19</span>}
                {comAtt === 4 && <span>LIVE</span>}
              </div>
            )}
          </div>

          <div className="Summary__bar">
            <div className="Summary__bar_value">
              <div
                style={{
                  backgroundColor: '#A9A9A9',
                  width: `${
                    calcPercentage(turnoutValue?.enti_p, turnoutValue?.enti_t) +
                    '%'
                  }`,
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className="Summary">
        {Object.keys(data).map((key, i) => {
          const row = data[key as keyof SummaryType];

          if (row.value === '-1' || blackList?.includes(key)) {
            return null;
          }

          return (
            <div className="Summary__row" key={i.toString()}>
              <div>
                <span className="Summary__row__title">{row.label}</span>
                {key === 'turnout' && (
                  <Sign isOpen={isSignOpen} onClick={onSignClick} />
                )}
              </div>
              <span>{`${row.value}`}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

const calcPercentage = (value: string | number, total: string | number) => {
  value =
    typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
  total =
    typeof total === 'string' ? parseFloat(total.replace(',', '.')) : total;

  return (value * 100) / total || 0;
};
