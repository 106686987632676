import { DataLevel, DynamicTurnout, DynamicTurnoutEnte } from './model';

export const getAffluenzaByLivelloPerc = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_circ: string | undefined,
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined
) => {
  switch (level) {
    case DataLevel.MAIN:
      return getAffluenzaItaliaPerc(dynamicTurnout);
    case DataLevel.CIRCOSCRIZIONE:
      return getAffluenzaCircoscrizionePerc(dynamicTurnout, level, cod_circ);
    case DataLevel.REGIONE:
      return getAffluenzaRegionePerc(dynamicTurnout, level, cod_reg);
    case DataLevel.PROVINCIA:
      return getAffluenzaProvinciaPerc(
        dynamicTurnout,
        level,
        cod_reg,
        cod_prov
      );
    case DataLevel.COMUNE:
      return getAffluenzaComunePerc(
        dynamicTurnout,
        level,
        cod_reg,
        cod_prov,
        cod_com
      );
    default:
      return 0;
  }
};

export const getAffluenzaByLivelloVoti = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined,
  cod_circ: string | undefined
) => {
  switch (level) {
    case DataLevel.MAIN:
      return getAffluenzaItaliaVoti(dynamicTurnout);
    case DataLevel.CIRCOSCRIZIONE:
      return getAffluenzaCircoscrizioneVoti(dynamicTurnout, level, cod_circ);
    case DataLevel.REGIONE:
      return getAffluenzaRegioneVoti(dynamicTurnout, level, cod_reg);
    case DataLevel.PROVINCIA:
      return getAffluenzaProvinciaVoti(
        dynamicTurnout,
        level,
        cod_reg,
        cod_prov
      );
    case DataLevel.COMUNE:
      return getAffluenzaComuneVoti(
        dynamicTurnout,
        level,
        cod_reg,
        cod_prov,
        cod_com
      );
    default:
      return 0;
  }
};

export const getAffluenzaByLivelloEleT = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined,
  cod_circ: string | undefined
) => {
  switch (level) {
    case DataLevel.MAIN:
      return getAffluenzaItaliaEleT(dynamicTurnout);
    case DataLevel.CIRCOSCRIZIONE:
      return getAffluenzaCircoscrizioneEleT(dynamicTurnout, cod_circ);
    case DataLevel.REGIONE:
      return getAffluenzaRegioneEleT(dynamicTurnout, cod_reg);
    case DataLevel.PROVINCIA:
      return getAffluenzaProvinciaEleT(
        dynamicTurnout,
        level,
        cod_reg,
        cod_prov
      );
    case DataLevel.COMUNE:
      return getAffluenzaComuneEleT(dynamicTurnout, cod_reg, cod_prov, cod_com);
    default:
      return 0;
  }
};

// AURE: QUI DA MODIFICARE
export const getAffluenzaCircoscrizione = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_circ: string | undefined
): any => {
  let codiceCirc = -1;
  if (cod_circ !== undefined) {
    codiceCirc = +cod_circ;
  }
  let indexCom = getActiveIndexComunicazione(dynamicTurnout, level, codiceCirc);
  let levelCirc = getLevelCirc(dynamicTurnout);
  if (levelCirc !== undefined) {
    let comunic = levelCirc.enti.enti_f.find(
      (r: DynamicTurnoutEnte) => r.cod == codiceCirc
    )?.com_vot[indexCom];

    if (comunic?.vot_t === 0 && indexCom > 0) {
      indexCom--;
      comunic = levelCirc.enti.enti_f.find(
        (r: DynamicTurnoutEnte) => r.cod == codiceCirc
      )?.com_vot[indexCom];
    }
    return comunic;
  }
  return { vot_t: 0, perc: 0 };
};

export const getAffluenzaItalia = (dynamicTurnout: DynamicTurnout[]) => {
  let indexCom = getActiveIndexComunicazione(dynamicTurnout, DataLevel.MAIN);
  let levelItalia = getLevelItalia(dynamicTurnout);

  let res = levelItalia?.enti?.ente_p?.com_vot[indexCom];
  if (res?.vot_t === 0 && indexCom > 0) {
    indexCom--;
    res = levelItalia?.enti?.ente_p?.com_vot[indexCom];
  }

  return res || { vot_t: 0, perc: 0 };
};

/**
 * Get affluenza regione perc
 * @param dynamicTurnout
 * @param level
 * @param cod_circ
 */
export const getAffluenzaCircoscrizionePerc = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_circ: string | undefined
) => {
  return (
    getAffluenzaCircoscrizione(dynamicTurnout, level, cod_circ)?.perc || '0'
  );
};

/**
 * Get affluenza regione perc
 * @param dynamicTurnout
 */
export const getAffluenzaItaliaPerc = (dynamicTurnout: DynamicTurnout[]) => {
  return getAffluenzaItalia(dynamicTurnout)?.perc || '0';
};

/**
 * Get affluenza regione perc
 * @param dynamicTurnout
 * @param level
 * @param cod_reg
 */
export const getAffluenzaRegionePerc = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined
) => {
  return getAffluenzaRegione(dynamicTurnout, level, cod_reg)?.perc || '0';
};

export const getAffluenzaRegioneVoti = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined
) => {
  return getAffluenzaRegione(dynamicTurnout, level, cod_reg)?.vot_t || 0;
};

export const getAffluenzaCircoscrizioneVoti = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_circ: string | undefined
) => {
  return (
    getAffluenzaCircoscrizione(dynamicTurnout, level, cod_circ)?.vot_t || 0
  );
};
export const getAffluenzaItaliaVoti = (dynamicTurnout: DynamicTurnout[]) => {
  return getAffluenzaItalia(dynamicTurnout)?.vot_t || 0;
};

export const getAffluenzaRegione = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined
) => {
  let codiceRegione = -1;
  if (cod_reg !== undefined) {
    codiceRegione = +cod_reg;
  }

  let indexCom = getActiveIndexComunicazione(
    dynamicTurnout,
    level,
    codiceRegione
  );
  let levelReg = getLevelReg(dynamicTurnout, codiceRegione);
  if (levelReg !== undefined) {
    let comunic = levelReg.enti?.ente_p?.com_vot[indexCom];

    if (comunic?.vot_t === 0 && indexCom > 0) {
      indexCom--;
      comunic = levelReg?.enti?.ente_p?.com_vot[indexCom];
    }
    return comunic;
  }
  return { vot_t: 0, perc: 0 };
};

export const getAffluenzaCircoscrizioneEleT = (
  dynamicTurnout: DynamicTurnout[],
  cod_circ: string | undefined
) => {
  let codiceCirc = -1;
  if (cod_circ !== undefined) {
    codiceCirc = +cod_circ;
  }
  let levelCirc = getLevelCirc(dynamicTurnout);
  if (levelCirc !== undefined) {
    const data = levelCirc.enti.enti_f.find(
      (r: DynamicTurnoutEnte) => r.cod == codiceCirc
    );
    return data?.ele_t || 0;
  }
  return 0;
};

export const getAffluenzaItaliaEleT = (dynamicTurnout: DynamicTurnout[]) => {
  let levelItalia = getLevelItalia(dynamicTurnout);
  if (levelItalia !== undefined) {
    const data = levelItalia.enti?.ente_p;
    return data?.ele_t || 0;
  }
  return 0;
};

export const getAffluenzaRegioneEleT = (
  dynamicTurnout: DynamicTurnout[],
  cod_reg: string | undefined
) => {
  let codiceRegione = -1;
  if (cod_reg !== undefined) {
    codiceRegione = +cod_reg;
  }

  let levelReg = getLevelReg(dynamicTurnout, codiceRegione);
  if (levelReg !== undefined) {
    return levelReg.enti?.ente_p?.ele_t || 0;
  }
  return 0;
};

export const getActiveIndexComunicazione = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  codiceRegione?: number,
  codiceProvincia = 0
) => {
  let levelWithData;
  switch (level) {
    case DataLevel.MAIN:
      levelWithData = getLevelItalia(dynamicTurnout);
      break;
    case DataLevel.CIRCOSCRIZIONE:
      levelWithData = getLevelCirc(dynamicTurnout);
      break;
    case DataLevel.REGIONE:
      levelWithData = getLevelReg(dynamicTurnout, codiceRegione!);
      break;
    case DataLevel.PROVINCIA:
      levelWithData = getLevelProv(dynamicTurnout, codiceRegione!);
      break;
    case DataLevel.COMUNE:
      levelWithData = getLevelComune(
        dynamicTurnout,
        codiceRegione!,
        codiceProvincia
      );
      break;
  }

  if (levelWithData !== undefined) {
    const arr = levelWithData?.enti?.ente_p?.com;
    return arr[arr.length - 1]?.com_att - 1 || 0;
  }

  return 0;
};

export const getLevelReg = (
  dynamicTurnout: DynamicTurnout[],
  codiceRegione: number
) => {
  return dynamicTurnout?.find(
    a => 'REGIONE' === a.int?.l_terr && +a.enti?.ente_p?.cod! === codiceRegione
  );
};

export const getLevelCirc = (dynamicTurnout: DynamicTurnout[]) => {
  return dynamicTurnout.find(
    a =>
      'ITALIA' === a.int?.l_terr &&
      a.enti?.enti_f &&
      a.enti?.enti_f[0].tipo === 'CIRCOSCRIZIONE'
  );
};

export const getLevelItalia = (dynamicTurnout: DynamicTurnout[]) => {
  return dynamicTurnout.find(
    a =>
      'ITALIA' === a.int?.l_terr &&
      a.enti?.enti_f &&
      a.enti?.enti_f[0].tipo !== 'CIRCOSCRIZIONE'
  );
};

// PROV

export const getAffluenzaProvinciaPerc = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined
) => {
  return (
    getAffluenzaProvincia(dynamicTurnout, level, cod_reg, cod_prov)?.perc || '0'
  );
};

export const getAffluenzaProvincia = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined
) => {
  let codiceRegione = -1;
  if (cod_reg !== undefined) {
    codiceRegione = +cod_reg;
  }
  let indexCom = getActiveIndexComunicazione(
    dynamicTurnout,
    level,
    codiceRegione
  );
  let levelProv = getLevelProv(dynamicTurnout, codiceRegione);
  if (levelProv !== undefined) {
    let codiceProvincia = -1;
    if (cod_prov !== undefined) {
      codiceProvincia = +cod_prov;
    }
    let comunic = levelProv.enti.enti_f.find(
      (p: DynamicTurnoutEnte) => p.cod === codiceProvincia
    )?.com_vot[indexCom];

    if (comunic?.vot_t === 0 && indexCom > 0) {
      // read previous comunication
      indexCom--;
      comunic = levelProv.enti.enti_f.find(
        (p: DynamicTurnoutEnte) => p.cod === codiceProvincia
      )?.com_vot[indexCom];
    }
    return comunic;
  }
  return { vot_t: 0, perc: 0, ele_t: 0 };
};

export const getAffluenzaProvinciaVoti = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined
) => {
  return (
    getAffluenzaProvincia(dynamicTurnout, level, cod_reg, cod_prov)?.vot_t || 0
  );
};

export const getAffluenzaProvinciaEleT = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined
) => {
  let codiceRegione = -1;
  if (cod_reg !== undefined) {
    codiceRegione = +cod_reg;
  }
  let levelProv = getLevelProv(dynamicTurnout, codiceRegione);
  if (levelProv !== undefined) {
    let codiceProvincia = -1;
    if (cod_prov !== undefined) {
      codiceProvincia = +cod_prov;
    }
    return (
      levelProv.enti.enti_f.find(
        (p: DynamicTurnoutEnte) => p.cod === codiceProvincia
      )?.ele_t || 0
    );
  }
  return 0;
};

export const getLevelProv = (
  dynamicTurnout: DynamicTurnout[],
  codiceRegione: number
) => {
  return dynamicTurnout?.find(
    a => 'REGIONE' === a.int?.l_terr && codiceRegione === a.enti?.ente_p?.cod
  );
};

// COMUNE

export const getAffluenzaComunePerc = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined
) => {
  return (
    getAffluenzaComune(dynamicTurnout, level, cod_reg, cod_prov, cod_com)
      ?.perc || '0'
  );
};

export const getAffluenzaComuneVoti = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined
) => {
  return (
    getAffluenzaComune(dynamicTurnout, level, cod_reg, cod_prov, cod_com)
      ?.vot_t || 0
  );
};

export const getAffluenzaComune = (
  dynamicTurnout: DynamicTurnout[],
  level: DataLevel,
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined
) => {
  let codiceRegione = -1;
  if (cod_reg !== undefined) {
    codiceRegione = +cod_reg;
  }
  let codiceProvincia = -1;
  if (cod_prov !== undefined) {
    codiceProvincia = +cod_prov;
  }
  let codiceComune = -1;
  if (cod_com !== undefined) {
    codiceComune = +cod_com;
  }
  let indexCom = getActiveIndexComunicazione(
    dynamicTurnout,
    level,
    codiceRegione,
    codiceProvincia
  );
  let levelProv = getLevelComune(
    dynamicTurnout,
    codiceRegione,
    codiceProvincia
  );
  if (levelProv !== undefined) {
    let comunic = levelProv.enti.enti_f.find(
      (p: DynamicTurnoutEnte) => 'COMUNE' === p.tipo && codiceComune === p.cod
    )?.com_vot[indexCom];

    if (comunic?.vot_t === 0 && indexCom > 0) {
      indexCom--;
      comunic = levelProv.enti.enti_f.find(
        (p: DynamicTurnoutEnte) => 'COMUNE' === p.tipo && codiceComune === p.cod
      )?.com_vot[indexCom];
    }

    return comunic;
  }
  return { vot_t: 0, perc: 0, ele_t: 0 };
};

export const getAffluenzaComuneEleT = (
  dynamicTurnout: DynamicTurnout[],
  cod_reg: string | undefined,
  cod_prov: string | undefined,
  cod_com: string | undefined
) => {
  let codiceRegione = -1;
  if (cod_reg !== undefined) {
    codiceRegione = +cod_reg;
  }
  let codiceProvincia = -1;
  if (cod_prov !== undefined) {
    codiceProvincia = +cod_prov;
  }
  let codiceComune = -1;
  if (cod_com !== undefined) {
    codiceComune = +cod_com;
  }
  let levelProv = getLevelComune(
    dynamicTurnout,
    codiceRegione,
    codiceProvincia
  );
  if (levelProv !== undefined) {
    return (
      levelProv.enti.enti_f.find(
        (p: DynamicTurnoutEnte) => 'COMUNE' === p.tipo && codiceComune === p.cod
      )?.ele_t || 0
    );
  }
  return 0;
};

export const getLevelComune = (
  dynamicTurnout: DynamicTurnout[],
  codiceRegione: number,
  codiceProvincia: number
) => {
  return dynamicTurnout?.find(
    a =>
      'PROVINCIA' === a.int?.l_terr &&
      codiceRegione === a.enti?.ente_p?.com[0].cod_reg &&
      codiceProvincia === a.enti?.ente_p?.cod
  );
};
