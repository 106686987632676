import { generateGradientTable } from '@/lib/candidatesHelpers';
import Config from '@/lib/config';
import {
  getLastVersion,
  getLastVersionTurnoutItaly,
  getResults,
} from '@/lib/getResults';
import { parsePercentage } from '@/lib/helpers';
import { usePrevious } from '@/lib/hookHelpers';
import {
  Candidate,
  DataLevel,
  DynamicTurnout,
  GradientRow,
  Region,
  Result,
  Year,
} from '@/lib/model';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';

export interface MainContextType {
  selectedYear: Year;
  setSelectedYear: (value: Year) => void;
  selectedRegion: Region;
  setSelectedRegion: (value: Region) => void;
  dataLevel: DataLevel;
  setDataLevel: (level: DataLevel) => void;
  results: Result[];
  setResults: (results: Result[]) => void;
  selectedResults: Result[];
  handleResult: (results: Result | Result[], isWithinList: boolean) => void;
  mainResult: Result | null;
  setMainResult: (result: Result) => void;
  lastResult: Result;
  lastProvince?: Result;
  selectedCandidate: Candidate | null;
  setSelectedCandidate: (candidate?: Candidate) => void;
  gradientTable: GradientRow[];
  innerCandidatesList: Candidate[];
  isTurnoutOpen: boolean;
  toggleTurnout: () => void;
  dynamicTurnout: DynamicTurnout[];
  setDynamicTurnout: (value: DynamicTurnout[]) => void;
  lastVersion: number;
  soloAffluenza2024: boolean;
  setSoloAffluenza2024: Dispatch<SetStateAction<boolean>>;
  loadingMap: boolean;
  setLoadingMap: Dispatch<SetStateAction<boolean>>;
  selectedCirc: number;
  setSelectedCirc: Dispatch<SetStateAction<number>>;

  //province e comuni recuperate dal BE
  fetchedProvinces: Result[];
  setFetchedProvinces: (provinces: Result[]) => void;
  fetchedMunicipalities: Result[];
  setFetchedMunicipalities: (communes: Result[]) => void;

  forceCoordinates: [number, number] | null;
  setForceCoordinates?: Dispatch<SetStateAction<[number, number] | null>>;

  lastVersionTurnoutItaly: number;
}

export const INITIAL_STATE = {
  selectedYear: Year.Y2024,
  setSelectedYear: () => {},
  selectedCirc: 1,
  setSelectedCirc: () => {},
  selectedRegion: Region.ALL,
  setSelectedRegion: () => {},
  dataLevel: DataLevel.CIRCOSCRIZIONE,
  setDataLevel: () => {},
  results: [],
  setResults: () => {},
  selectedResults: [],
  handleResult: () => {},
  mainResult: {} as Result,
  setMainResult: () => {},
  lastResult: {} as Result,
  lastProvince: {} as Result,
  selectedCandidate: {} as Candidate,
  setSelectedCandidate: () => {},
  gradientTable: [],
  innerCandidatesList: [],
  isTurnoutOpen: false,
  toggleTurnout: () => {},
  dynamicTurnout: [],
  setDynamicTurnout: () => {},
  lastVersion: 1,
  lastVersionTurnoutItaly: 1,
  soloAffluenza2024: false,
  setSoloAffluenza2024: () => {},
  loadingMap: false,
  setLoadingMap: () => {},

  fetchedProvinces: [],
  setFetchedProvinces: () => {},
  fetchedMunicipalities: [],
  setFetchedMunicipalities: () => {},
  forceCoordinates: null,
  setForceCoordinates: () => {},
};

const MainContext = React.createContext<MainContextType>(INITIAL_STATE);

export const useMainContext = () => React.useContext(MainContext);

export const MainProvider: React.FC<Partial<React.PropsWithChildren>> = ({
  children,
}) => {
  const [selectedYear, setSelectedYear] = React.useState<Year>(
    INITIAL_STATE.selectedYear
  );
  const [selectedRegion, setSelectedRegion] = React.useState<Region>(
    INITIAL_STATE.selectedRegion
  );
  const [forceCoordinates, setForceCoordinates] = React.useState<
    [number, number] | null
  >(null);
  const [selectedCirc, setSelectedCirc] = React.useState<number>(
    INITIAL_STATE.selectedCirc
  );
  const [dataLevel, setDataLevelContext] = React.useState<DataLevel>(
    INITIAL_STATE.dataLevel
  );
  const [results, setResults] = React.useState<Result[]>([]);
  const [selectedResults, setSelectedResults] = React.useState<Result[]>([]);
  const [mainResult, setMainResult] = React.useState<Result | null>(null);
  const [selectedCandidate, setSelectedCandidateContext] =
    React.useState<Candidate | null>(null);
  const [isTurnoutOpen, setIsTurnoutOpen] = React.useState(
    INITIAL_STATE.isTurnoutOpen
  );
  const [dynamicTurnout, setDynamicTurnout] = React.useState<DynamicTurnout[]>(
    INITIAL_STATE.dynamicTurnout
  );
  const [lastVersion, setLastVersion] = React.useState(
    INITIAL_STATE.lastVersion
  );
  const [lastVersionTurnoutItaly, setLastVersionTurnoutItaly] = React.useState(
    INITIAL_STATE.lastVersion
  );

  const [soloAffluenza2024, setSoloAffluenza2024] = React.useState<boolean>(
    INITIAL_STATE.soloAffluenza2024
  );
  const prevSelectedRegion = usePrevious<Region>(
    selectedRegion,
    INITIAL_STATE.selectedRegion
  );

  const prevSelectedYear = usePrevious<Year>(
    selectedYear,
    INITIAL_STATE.selectedYear
  );

  const timeOut = React.useRef<NodeJS.Timeout>();
  const timeOutTurnoutItaly = React.useRef<NodeJS.Timeout>();

  const lastResult = selectedResults[selectedResults.length - 1] as Result;
  const lastProvince = selectedResults.find(
    r => r.level === DataLevel.PROVINCIA
  );

  const [loadingMap, setLoadingMap] = React.useState<boolean>(
    INITIAL_STATE.loadingMap
  );

  const comeBackToTop = () => {
    // scroll back to map
    document.getElementById(`root-${Config.projectName}`)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const [fetchedProvinces, setFetchedProvinces] = React.useState<Result[]>([]);
  const [fetchedMunicipalities, setFetchedMunicipalities] = React.useState<
    Result[]
  >([]);

  /**
   * Fetch last version
   *
   *
   */
  React.useEffect(() => {
    const fn = async () => {
      clearTimeout(timeOut.current);

      const version = await getLastVersion();
      if (version !== lastVersion && selectedYear === Year.Y2024) {
        setLastVersion(version);
      }

      const MINUTE = 1000 * 60;
      timeOut.current = setTimeout(fn, MINUTE);
    };

    setTimeout(() => fn(), 500);

    return () => {
      clearTimeout(timeOut.current);
    };
  }, [lastVersion]);

  /**
   * usato per il last version del turnout italia
   *
   *
   */
  React.useEffect(() => {
    const fn = async () => {
      clearTimeout(timeOutTurnoutItaly.current);

      const version = await getLastVersionTurnoutItaly();
      if (version !== lastVersionTurnoutItaly && selectedYear === Year.Y2024) {
        setLastVersionTurnoutItaly(version);
      }

      const MINUTE = 1000 * 10;
      timeOutTurnoutItaly.current = setTimeout(fn, MINUTE);
    };

    fn();

    return () => {
      clearTimeout(timeOutTurnoutItaly.current);
    };
  }, [lastVersionTurnoutItaly]);

  /**
   * Choose an inner candidate
   *
   *
   */
  const setSelectedCandidate = React.useCallback(
    (candidate?: Candidate) => {
      if (!loadingMap) {
        setLoadingMap(true);
      }

      if (isTurnoutOpen) {
        setIsTurnoutOpen(false);
      }

      setSelectedCandidateContext(() => {
        if (!candidate) {
          return null;
        }

        return candidate;
      });

      // scroll back to map
      comeBackToTop();
    },
    [isTurnoutOpen]
  );

  /**
   * Change data level
   *
   *
   */
  const setDataLevel = React.useCallback(
    (level: DataLevel) => {
      // if (selectedCandidate) { // TODO QUIIII
      //   // close selected candidate if level changes
      //   setSelectedCandidate();
      // }

      setDataLevelContext(level);
    },
    [selectedCandidate, setSelectedCandidate]
  );

  /**
   * Set main result for the first time
   *
   *
   */
  React.useEffect(() => {
    if (!mainResult) {
      return;
    }

    if (selectedRegion !== prevSelectedRegion) {
      setDataLevel(INITIAL_STATE.dataLevel);
    }

    setSelectedResults(prevResults => {
      if (mainResult.region !== selectedRegion) {
        return prevResults;
      }

      return [
        mainResult,
        ...prevResults.filter(r => r.region === selectedRegion).slice(1),
      ];
    });
  }, [mainResult, selectedRegion, prevSelectedRegion, setDataLevel]);

  /**
   * year change, reset to main settings
   *
   *
   */
  React.useEffect(() => {
    // wait until main result is updated
    // setSelectedCandidate();

    if (prevSelectedYear !== selectedYear) {
      setSelectedCandidate();
      setFetchedProvinces([]);
      setFetchedMunicipalities([]);
    }

    //TODO UTILIZZARE ANZI INDEXOF
    const areResultsOutdated = selectedResults.some(
      r => r.year !== selectedYear
    );

    // check if there are selected results with old selected year
    if (!areResultsOutdated) {
      return;
    }

    if (isTurnoutOpen && selectedYear !== Year.Y2024) {
      setIsTurnoutOpen(false);
    }

    if (selectedRegion === undefined) {
      return;
    }

    const fn = async () => {
      // find all provinces with current selected options

      // console.log(
      //   'GETRESULTS livello provinces',
      //   selectedYear,
      //   selectedRegion,
      //   DataLevel.PROVINCIA,
      //   dynamicTurnout
      // );

      // QUESTO E" STATO MESSO PERCHE ALTRIMENTI ANDAVA IN LOOP
      // if (fetchedProvinces && fetchedProvinces.length > 0) {
      //   return;
      // }
      // let maxCountWhile = 10;

      // console.log(
      //   'getResults provinces 3',
      //   fetchedMunicipalities,
      //   fetchedProvinces,
      //   selectedYear,
      //   selectedRegion,
      //   DataLevel.COMUNE,
      //   dynamicTurnout,
      //   lastResult?.idCodes?.codCirc,
      //
      //   '2022',
      //   Year.Y2022,
      //   '2024',
      //   Year.Y2024
      // );
      let provinces = await getResults(
        false,
        selectedYear,
        selectedRegion,
        DataLevel.PROVINCIA,
        dynamicTurnout
      );
      setFetchedProvinces(provinces);

      // while (provinces.length === 0 && maxCountWhile > 0) {
      //   console.log('descreasing maxCountWhile', maxCountWhile);
      //   maxCountWhile--;
      //   console.log('descreased maxCountWhile', maxCountWhile);
      //   provinces = await getResults(
      //     false,
      //     selectedYear,
      //     selectedRegion,
      //     DataLevel.PROVINCIA,
      //     dynamicTurnout
      //   );
      // }

      const currentCodProv = lastProvince?.idCodes?.codProv;
      //const currentCodProv = lastProvince?.idCodes?.codProv || lastResult?.idCodes?.codProv;

      const province = provinces.find(
        province => province.idCodes.codProv == currentCodProv
      );

      // check if last result is a comune

      if (lastResult.idCodes.codCom !== 'undefined') {
        // console.log(
        //   'GETRESULTS livello provinces',
        //   selectedYear,
        //   selectedRegion,
        //   DataLevel.COMUNE,
        //   dynamicTurnout,
        //   lastResult.idCodes
        // );
        const comuni = await getResults(
          false,
          selectedYear,
          selectedRegion,
          DataLevel.COMUNE,
          dynamicTurnout,
          lastResult.idCodes
        );

        const comune = comuni.find(
          c =>
            c.idCodes.codCom === lastResult.idCodes.codCom &&
            c.idCodes.codProv === lastResult.idCodes.codProv
        );

        // no comune, we don't continue
        if (!comune) {
          return;
        }

        if (!province) {
          // update selections (here we follow a rule of main result, province and comune,
          // if there are other selections they will be lost - this is not the current scenario)
          setSelectedResults(prevResults => {
            const mainResult = prevResults[0];
            return [mainResult, comune];
          });
        } else {
          // update selections (here we follow a rule of main result, province and comune,
          // if there are other selections they will be lost - this is not the current scenario)
          setSelectedResults(prevResults => {
            const mainResult = prevResults[0];
            return [mainResult, province, comune];
          });
        }

        return;
      }

      if (!province) {
        // no comune, no province, we update the selected results object
        setSelectedResults(prevResults => {
          const mainResult = prevResults[0];

          return [mainResult];
        });
      } else {
        // no comune, just province, we update the selected results object
        setSelectedResults(prevResults => {
          const mainResult = prevResults[0];

          return [mainResult, province];
        });
      }
    };

    setTimeout(() => {
      fn();
    }, 100);
  }, [
    prevSelectedYear,
    selectedYear,
    mainResult,
    selectedResults,
    dynamicTurnout,
    lastProvince,
    lastResult,
    selectedRegion,
  ]);

  /**
   * Adding a new selected result
   *
   *
   */
  const handleResult = (results: Result | Result[], isWithinList: boolean) => {
    if (!Array.isArray(results)) {
      results = [results];
    }

    const firstResultFromList = results[0] as Result;
    const lastResultFromList = results[results.length - 1] as Result;

    if (
      (lastResultFromList.level === DataLevel.MAIN ||
        lastResultFromList.level === DataLevel.CIRCOSCRIZIONE) &&
      dataLevel === DataLevel.COMUNE
    ) {
      setDataLevel(INITIAL_STATE.dataLevel);
    } else if (lastResultFromList.level === DataLevel.CIRCOSCRIZIONE) {
      setDataLevel(DataLevel.REGIONE);
    } else if (lastResultFromList.level === DataLevel.REGIONE) {
      setDataLevel(DataLevel.PROVINCIA);
    } else if (lastResultFromList.level === DataLevel.PROVINCIA) {
      setDataLevel(DataLevel.COMUNE);
    }

    setSelectedResults(prevResults => {
      if (isWithinList) {
        return prevResults.filter(r => r.level <= firstResultFromList.level);
      }

      return [
        ...prevResults.filter(r => r.level < firstResultFromList.level),
        ...(results as Result[]),
      ];
    });
  };

  /**
   * Filter candidates list to chosen candidate
   *
   *
   */
  const innerCandidatesList = React.useMemo(() => {
    if (!selectedCandidate) {
      return [];
    }

    return results
      .reduce((acc: Candidate[], result: Result) => {
        const foundCoalition = result.coalitions.find(
          coalition => coalition.coalitionId === selectedCandidate.coalitionId
        );

        if (!foundCoalition) {
          return acc;
        }

        const { candidates, ...coalition } = foundCoalition;
        let valueToReturn: Candidate = { ...coalition };

        if (selectedCandidate.candidateId) {
          const candidate = candidates.find(
            candidate => candidate.candidateId === selectedCandidate.candidateId
          ) as Candidate;

          valueToReturn = { ...candidate };
        }

        valueToReturn.label = result.placeName;

        return [...acc, valueToReturn];
      }, [])
      .sort(({ percentage: a }, { percentage: b }) => b - a);
  }, [selectedCandidate, results]);

  /**
   * Generate colors table
   *
   *
   */
  const gradientTable = React.useMemo(() => {
    if (isTurnoutOpen) {
      return generateGradientTable(
        results.map(r => r.summary.turnout),
        'value'
      );
    }

    return generateGradientTable(innerCandidatesList, 'percentage');
  }, [innerCandidatesList, isTurnoutOpen, results]);

  /**
   * Generate colors table
   *
   *
   */
  const resultsWithSelectedCandidate = React.useMemo(() => {
    if (!selectedCandidate && !isTurnoutOpen) {
      return results;
    }

    return results.reduce((acc: Result[], result) => {
      let fillColor: string = result.mapLayer.fillColor;
      let valueToConsider = parsePercentage(result.summary.turnout.value);
      if (!isTurnoutOpen) {
        const innerCandidate = innerCandidatesList.find(
          c => c.label === result.placeName
        );

        valueToConsider = innerCandidate?.percentage || 0;

        if (selectedCandidate) {
          fillColor = selectedCandidate?.fillColor;
        }
      } else {
        // state color for affluenza
        fillColor = '#a9a9a9';
      }

      // When switching from 2023 heatmap selected party to 2022,
      // to avoid crash (gradientTable results empty after the switch of the year)
      if (gradientTable.length === 0) {
        return [];
      }

      const { opacity } =
        gradientTable.find(row => valueToConsider <= row.value) ||
        gradientTable[0]; // if no result has been found, use first gradient.

      return [
        ...acc,
        {
          ...result,
          mapLayer: {
            ...result.mapLayer,
            fillColor,
            opacity,
          },
        },
      ];
    }, []);
  }, [
    selectedCandidate,
    innerCandidatesList,
    gradientTable,
    results,
    isTurnoutOpen,
  ]);

  /**
   * Generate colors table
   *
   *
   */
  const toggleTurnout = () => {
    if (selectedCandidate) {
      setSelectedCandidate();
    }

    setIsTurnoutOpen(prevTurnout => !prevTurnout);

    // scroll back to map
    comeBackToTop();
  };

  /**
   *
   *
   *
   */
  return (
    <MainContext.Provider
      value={{
        selectedYear,
        setSelectedYear,
        selectedRegion,
        setSelectedRegion,
        dataLevel,
        setDataLevel,
        results: resultsWithSelectedCandidate,
        setResults,
        selectedResults,
        handleResult,
        mainResult,
        setMainResult,
        lastResult,
        lastProvince,
        selectedCandidate,
        setSelectedCandidate,
        gradientTable,
        innerCandidatesList,
        isTurnoutOpen,
        toggleTurnout,
        dynamicTurnout,
        setDynamicTurnout,
        lastVersion,
        soloAffluenza2024,
        setSoloAffluenza2024,
        loadingMap,
        setLoadingMap,
        selectedCirc,
        setSelectedCirc,
        fetchedProvinces,
        setFetchedProvinces,
        fetchedMunicipalities,
        setFetchedMunicipalities,
        forceCoordinates,
        setForceCoordinates,
        lastVersionTurnoutItaly,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
