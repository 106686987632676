import MappingCameraComuni from '@/data/mapping-camera-comuni7917.json';

// Pre-process data into a Map for faster lookup
const provinceMap = new Map<string, any>();
const comuneMap = new Map<string, any>();

export const convertIdProvince = (cod_reg?: string, cod_prov?: string) => {
  const province = convertIdProvinceInner(cod_reg, cod_prov);

  if (province) {
    return province.ISTAT_PROV;
  } else {
    console.log(
      'convertIdProvince ISTAT_PROV not found for',
      cod_reg,
      cod_prov
    );
  }
  return cod_prov || '';
};

export const convertIdProvinceNomeProvincia = (
  cod_reg?: string,
  cod_prov?: string
) => {
  const province = convertIdProvinceInner(cod_reg, cod_prov);

  if (province) {
    return province.nome_provincia;
  } else {
    console.log(
      'convertIdProvince nome_provincia not found for',
      cod_reg,
      cod_prov
    );
  }
  return cod_prov || '';
};

export const convertIdProvinceInner = (cod_reg?: string, cod_prov?: string) => {
  if (cod_reg?.charAt(0) === '0') {
    cod_reg = cod_reg.substring(1);
  }
  if (cod_prov?.charAt(0) === '0') {
    cod_prov = cod_prov.substring(1);
  }
  if (provinceMap.size === 0) {
    MappingCameraComuni.forEach(e => {
      provinceMap.set(`${e.cod_regione}-${e.cod_provincia}`, e);
    });
  }
  return provinceMap.get(`${cod_reg}-${cod_prov}`);
};

export const convertIdComune = (
  cod_reg?: string,
  cod_prov?: string,
  cod_com?: string
) => {
  const comune = convertIdComuneInner(cod_reg, cod_prov, cod_com);

  if (comune) {
    return comune.PRO_COM;
  } else {
    console.log(
      'convertIdComune PRO_COM not found for',
      cod_reg,
      cod_prov,
      cod_com
    );
  }
  return cod_com || '';
};

export const convertIdComuneToNomeComune = (
  cod_reg?: string,
  cod_prov?: string,
  cod_com?: string
) => {
  const comune = convertIdComuneInner(cod_reg, cod_prov, cod_com);

  if (comune) {
    return comune.nome_comune;
  } else {
    console.log(
      'convertIdComuneToNomeComune nome_comune not found for',
      cod_reg,
      cod_prov,
      cod_com
    );
  }
  return cod_com || '';
};

export const convertIdComuneInner = (
  cod_reg?: string,
  cod_prov?: string,
  cod_com?: string
) => {
  if (cod_reg?.charAt(0) === '0') {
    cod_reg = cod_reg.substring(1);
  }
  if (cod_prov?.charAt(0) === '0') {
    cod_prov = cod_prov.substring(1);
  }
  if (cod_com?.charAt(0) === '0') {
    cod_com = cod_com.substring(1);
  }

  const key = `${cod_reg}-${cod_prov}-${cod_com}`;
  if (comuneMap.size === 0) {
    MappingCameraComuni.forEach(e => {
      const key = `${e.cod_regione}-${e.cod_provincia}-${e.cod_comune}`;
      comuneMap.set(key, e);
    });
  }
  return comuneMap.get(key);
};
