import { DataLevel, DynamicTurnout, Result } from '@/lib/model';

export const getTournoutValue = (
  dataLevel: DataLevel,
  dynamicTurnout: DynamicTurnout[],
  lastResult: Result
) => {
  const found = dynamicTurnout.find(d => {
    if (dataLevel === DataLevel.MAIN) {
      return (
        'ITALIA' === d.int?.l_terr &&
        d.enti?.enti_f &&
        d.enti?.enti_f[0].tipo !== 'CIRCOSCRIZIONE'
      );
    }

    if (dataLevel === DataLevel.CIRCOSCRIZIONE) {
      return (
        'ITALIA' === d.int?.l_terr &&
        d.enti?.enti_f &&
        d.enti?.enti_f[0].tipo === 'CIRCOSCRIZIONE'
      );
    }

    if (
      dataLevel === DataLevel.REGIONE ||
      lastResult?.idCodes?.codReg === 'undefined'
    ) {
      return (
        d.int?.l_terr == 'REGIONE' &&
        d.enti?.ente_p?.cod == lastResult?.idCodes?.codReg
      );
    }

    if (dataLevel === DataLevel.PROVINCIA) {
      return (
        d.int?.l_terr === 'PROVINCIA' &&
        // Deve essere == perche non deve fare il cast
        d.enti?.ente_p?.cod == lastResult?.idCodes?.codProv
      );
    }

    if (
      dataLevel === DataLevel.COMUNE ||
      // Deve essere == perche non deve fare il cast
      lastResult?.idCodes?.codCom === 'undefined'
    ) {
      return (
        d.int?.l_terr === 'PROVINCIA' &&
        d.enti?.ente_p?.cod == lastResult?.idCodes?.codProv
      );
    }

    if (
      dataLevel === DataLevel.COMUNE &&
      lastResult?.idCodes?.codCom !== 'undefined'
    ) {
      // console.log('lastResult?.idCodes?.codCom', lastResult?.idCodes?.codCom);
      return (
        d.int?.l_terr === 'PROVINCIA' &&
        d.enti?.ente_p?.cod == lastResult?.idCodes?.codProv &&
        d.enti?.enti_f?.findIndex(el => el.cod == lastResult?.idCodes?.codCom) >
          -1
      );
    }
  });

  let comVot: any;
  let comunicazioneAttiva: any;

  let value;

  if (
    dataLevel === DataLevel.CIRCOSCRIZIONE &&
    found?.enti?.enti_f &&
    found?.enti?.enti_f[0].tipo === 'CIRCOSCRIZIONE'
  ) {
    const foundCirc = found?.enti?.enti_f?.find(
      el => el.cod === lastResult?.idCodes?.codCirc
    );

    comVot = foundCirc?.com_vot || [];
  } else if (
    dataLevel === DataLevel.COMUNE &&
    lastResult?.idCodes?.codCom !== 'undefined'
  ) {
    let comune = found?.enti?.enti_f?.find(
      el => el.cod == lastResult?.idCodes?.codCom
    );
    comVot = comune?.com_vot || [];
  } else {
    comVot = found?.enti?.ente_p?.com_vot || [];
  }

  comunicazioneAttiva = found?.enti?.ente_p?.com[0].com_att || 0;

  // Se il valore di enti_p della comunicazione corrente e' 0 allora cerco di prendere il valore della comunicazione precedente
  // Se il valore di enti_p della comunicazione corrente e' 0 ed e' la prima comunicazione allora prendo il valore della comunicazione corrente

  value = comVot[comunicazioneAttiva - 1];

  if (value?.enti_p === 0 && comunicazioneAttiva > 1) {
    value = comVot[comunicazioneAttiva - 2];
  }

  // se non trovo nulla prendo la prima disponibile
  if (value === undefined && comVot?.length > 0) {
    value = comVot[0];
  }

  return { ...value, comunicazioneAttiva };
};
