import type { LayerProps } from 'react-map-gl';

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer: LayerProps = {
  id: 'data',
  'source-layer': '2024_Circoscrizioni_EU_Italia_20',
  type: 'fill',
  paint: {
    'fill-color': {
      type: 'interval',
      // type: 'identity',
      property: 'COD_RIP',
      stops: [],
    },
    'fill-opacity': 1,
    'fill-outline-color': 'rgba(68,68,68,0.5)',
  },
  beforeId: 'waterway-shadow',
};
