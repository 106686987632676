export const formatCapitalize = (str: string) => {
  str = str.toLowerCase();

  let arr = str.split("'");
  let list: string[] = [];
  arr.forEach(el => list.push(el.charAt(0).toUpperCase() + el.slice(1)));

  const newArr = list.join("'");
  let space = newArr.split(' ');
  list = [];
  space.forEach(el => list.push(el.charAt(0).toUpperCase() + el.slice(1)));
  return list
    .join(' ')
    .replace('Ii', 'II')
    .replace('Iii', 'III')
    .replace('IIi', 'III')
    .replace('Iv', 'IV');
};
