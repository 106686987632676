import * as React from 'react';
import './index.scss';

export interface FilterProps {
  label: string;
  selected?: boolean;
  onClick: () => void;
}

export const Filter: React.FC<FilterProps> = ({ label, selected, onClick }) => (
  <div
    className={['Filter', selected && 'selected'].join(' ')}
    onClick={onClick}
  >
    {label}
  </div>
);
