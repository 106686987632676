import { useMainContext } from '@/lib/context';
import { Result, Year } from '@/lib/model';
import * as React from 'react';
import { Categories } from './Categories';
import { Coalitions } from './Coalitions';
import './index.scss';
import { Live } from './Live';
import { Party } from './Party';
import { Summary } from './Summary';

export const Results: React.FC = () => {
  const {
    lastResult,
    selectedResults,
    selectedCandidate,
    setSelectedCandidate,
    innerCandidatesList,
    gradientTable,
    isTurnoutOpen,
    toggleTurnout,
    selectedYear,
    handleResult,
    dataLevel,
    soloAffluenza2024,
    dynamicTurnout,
  } = useMainContext();

  const mainResult = selectedResults[0] as Result;
  const shownResults = [
    mainResult,
    ...(selectedResults.length > 1 ? selectedResults.slice(-1) : []),
  ];

  const isCurrentYear = selectedYear === Year.Y2024;

  const onClickCategories = (result: Result) => {
    setSelectedCandidate();
    handleResult(result, true);
  };

  return (
    <>
      {/* SOLO 2024 se l'affluenza e' attiva*/}
      {soloAffluenza2024 && isCurrentYear && (
        <div className="Results">
          <Categories results={shownResults} onClick={onClickCategories} />

          <Summary
            data={lastResult.summary}
            isSignOpen={isTurnoutOpen}
            onSignClick={toggleTurnout}
            showBar={true}
            dataLevel={dataLevel}
            dynamicTurnout={dynamicTurnout}
            lastResult={lastResult}
            blackList={[
              'blankCards',
              'contestedCards',
              'nullCards',
              'validVotes',
            ]}
            category={shownResults[shownResults?.length - 1]}
          />
        </div>
      )}

      {/* PER TUTTI GLI ALTRI CASI*/}
      {(!isCurrentYear || (isCurrentYear && !soloAffluenza2024)) && (
        <div className="Results">
          <Categories results={shownResults} onClick={onClickCategories} />

          {isCurrentYear && (
            <Live
              currentNumber={lastResult.scrutinized.current}
              total={lastResult.scrutinized.total}
              dynamicTurnout={dynamicTurnout}
            />
          )}

          {selectedCandidate ? (
            <Party
              selectedCandidate={selectedCandidate}
              candidates={innerCandidatesList}
              onCandidateClick={setSelectedCandidate}
              gradientTable={gradientTable}
              dataLevel={dataLevel}
            />
          ) : (
            <>
              <Coalitions
                data={lastResult.coalitions}
                selectedCandidate={selectedCandidate}
                onCandidateClick={setSelectedCandidate}
              />

              <Summary
                data={lastResult.summary}
                isSignOpen={isTurnoutOpen}
                onSignClick={toggleTurnout}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
