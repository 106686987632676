import { YEARS } from '@/lib/fixedTypes';
import { Year } from '@/lib/model';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

export interface SelectYearProps {
  value: Year;
  onYearChange: (value: Year) => void;
}

export const SelectYear: React.FC<SelectYearProps> = ({
  value,
  onYearChange,
}) => (
  <Select
    value={value}
    onChange={event => onYearChange(event.target.value as Year)}
    className="Input"
  >
    {YEARS.map((year, index) => (
      <MenuItem value={year.value} key={index.toString()}>
        {year.label}
      </MenuItem>
    ))}
  </Select>
);
