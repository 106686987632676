import * as React from 'react';
import './index.scss';

export const CoalitionsHeader: React.FC = () => (
  // <p className="CoalitionsHeader">
  //     <span className="spanHeaders"></span>
  //   <span>Voti</span>
  //   <span>%</span>
  // </p>

  <div className="CoalitionsHeader">
    <div className="CoalitionsHeader__bar"></div>
    <div className="CoalitionsHeader__values">
      <span>Voti</span>
      <span>%</span>
    </div>
  </div>
);
