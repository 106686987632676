import { DataLevel, Region, Year } from '@/lib/model';
import { parsePercentage } from './helpers';

import { default as CoalizioniInfoColori2019 } from '@/data/2019/2019-coalizioni-colori.json';
import { default as ListeColori2019 } from '@/data/2019/2019-liste-colori.json';

import { default as CoalizioniInfoColori2022 } from '@/data/2022/2022-camera-coalizioni-colori.json';
import { default as ListeColori2022 } from '@/data/2022/2022-camera-liste-colori.json';

import { default as CoalizioniInfoColori2024 } from '@/data/2024/2024-europee-coalizioni-info-colori.json';
import { default as ListeColori2024 } from '@/data/2024/2024-europee-partiti-liste-colori.json';

import { formatCapitalize } from '@/utils/format-capitalize.util';

export const getPartyList = (year: Year, region: Region) => {
  let list = ListeColori2022;

  if (year === Year.Y2019) {
    list = ListeColori2019;
  } else if (year === Year.Y2022) {
    list = ListeColori2022;
  } else if (year === Year.Y2024) {
    list = ListeColori2024;
  }

  return list;
};

export const getCoalitionsList = (year: Year, region: Region) => {
  let list = CoalizioniInfoColori2022;

  if (year === Year.Y2019) {
    list = CoalizioniInfoColori2019;
  } else if (year === Year.Y2022) {
    list = CoalizioniInfoColori2022;
  } else if (year === Year.Y2024) {
    list = CoalizioniInfoColori2024;
  }

  return list;
};

export const getPartyColor = (year: Year, region: Region, pos: number) => {
  const list = getPartyList(year, region);
  return list.find(c => c.pos === pos)?.colore || '#D9D9D9';
};

export const getName = (
  year: Year,
  region: Region,
  isCoalition: boolean,
  o: any
) => {
  const parties = getPartyList(year, region);
  const coalitions = getCoalitionsList(year, region);

  if (isCoalition) {
    let coalition = coalitions.find(c => c.cod_coal === o?.pos);
    if (coalition !== undefined) {
      return coalition.coalizione;
    }
  }

  if (
    !parties.find(c => c.pos === o?.pos)?.desc_lis &&
    !parties.find(c => c.cod_coal === o?.pos)?.desc_lis
  ) {
    // debugger;
  }

  return (
    parties.find(c => c.pos === o?.pos)?.desc_lis ||
    parties.find(c => c.cod_coal === o?.pos)?.desc_lis ||
    'N/A'
  );
};

export const getFullNameCandidate = (
  year: Year,
  region: Region,
  isCoalition: boolean,
  o: any,
  isMultiCollegio = false
) => {
  const coalitions = getCoalitionsList(year, region);
  const is2022 = year === Year.Y2022;
  const is2019 = year === Year.Y2019;

  if (isCoalition) {
    let coal = coalitions.find(c => c.cod_coal === o?.pos);
    if (coal !== undefined) {
      if (!isMultiCollegio) {
        if (o.cogn !== undefined) {
          return formatCapitalize(o.nome + ' ' + o.cogn);
        } else if (o.desc_pos !== undefined && !is2022 && !is2019) {
          return formatCapitalize(o.desc_pos);
        }
      }
    }
  }

  return null;
};

export const getMostraDettaglio = (year: Year, region: Region, pos: number) => {
  const coalitions = getCoalitionsList(year, region);

  return coalitions.find((c: any) => c.cod_coal === pos)?.mostraDettaglio;
};

export const getCoalitionColorByPartitoListaId = (
  year: Year,
  region: Region,
  pos: number
) => {
  const parties = getPartyList(year, region);
  const coalitions = getCoalitionsList(year, region);

  let party = parties.find(c => c.pos === pos);
  if (
    party?.cod_coal === 1 || // CENTROSINISTRA 2024
    party?.cod_coal === 2 ||
    party?.cod_coal === 5 ||
    party?.cod_coal === 22 ||
    party?.cod_coal === 17 ||
    party?.cod_coal === 27
  ) {
    // coalition color only if CENTRODESTRA or CENTROSINISTRA
    return (
      coalitions.find(c => c.cod_coal === party?.cod_coal)?.colore || '#D9D9D9'
    );
  }

  return party?.colore || '#D9D9D9';
};

/**
 *
 * @param year
 * @param region
 * @param level
 * @param coalition
 * @return {string} Colore
 */
export const getCoalitionColor = (
  year: Year,
  region: Region,
  level: DataLevel,
  coalition: any,
  withoutZeroCheck = true
): string => {
  const coalitions = getCoalitionsList(year, region);

  if (level === DataLevel.PROVINCIA && year !== Year.Y2024) {
    if (
      (year === Year.Y2022 && coalition?.pos === 22) ||
      (year === Year.Y2022 && coalition?.pos === 17) ||
      (year === Year.Y2019 && coalition?.pos === 22) ||
      (year === Year.Y2019 && coalition?.pos === 17)
    ) {
      return (
        coalitions.find(c => c.cod_coal === coalition?.pos)?.colore || '#D9D9D9'
      );
    }

    return getCoalitionColorByPartitoListaId(
      year,
      region,
      (coalition?.liste || [])[0]?.pos || 0
    );
  } else if (
    level === DataLevel.COMUNE &&
    !(
      year === Year.Y2022 &&
      (coalition?.pos === 17 || coalition?.pos === 22)
    ) &&
    !(
      year === Year.Y2019 &&
      (coalition?.pos === 17 || coalition?.pos === 22)
    ) &&
    !(year === Year.Y2024 && (coalition?.pos === 2 || coalition?.pos === 1)) &&
    year !== Year.Y2024
  ) {
    return getPartyColor(year, region, (coalition?.liste || [])[0]?.pos || 0);
  } else {
    // default CIRCOSCRIZIONE

    if (withoutZeroCheck && !coalition?.voti && !coalition?.voti_uni) {
      return '#D9D9D9';
    }
    return (
      coalitions?.find(c => c.cod_coal === coalition?.pos)?.colore || '#D9D9D9'
    );
  }
};

export const getCoalitionColorForPopup = (
  year: Year,
  region: Region,
  level: DataLevel,
  coalition: any
): string => {
  return getCoalitionColor(year, region, level, coalition, false);
};

export const generateGradientTable = <T>(list: T[], key: keyof T) => {
  if (!list.length) {
    return [];
  }

  let min = parsePercentage(`${list[0][key]}`) || 0;
  let max = parsePercentage(`${list[0][key]}`) || 0;

  list.forEach(c => {
    const check = parsePercentage(`${c[key]}`);

    if (check < min) {
      min = check;
    } else if (check > max) {
      max = check;
    }
  });

  const diff = max - min;
  const totalSteps = 7;
  const step = parseFloat((diff / totalSteps).toFixed(2));
  let prevValue = min;

  return Array.from({ length: totalSteps }).map((_, i) => {
    const opacity = parseFloat((0.15 * (i + 1)).toFixed(2));
    const value = i === totalSteps - 1 ? max : prevValue + step;
    prevValue = value;

    return {
      opacity: opacity > 1 ? 1 : opacity,
      value,
    };
  });
};
