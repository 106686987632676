import { Coalition } from '@/components/Coalition';
import { CoalitionsHeader } from '@/components/CoalitionsHeader';
import { Candidate, DataLevel, GradientRow } from '@/lib/model';
import { formatDecimal } from '@/utils/format-decimal.util';
import { formatInteger } from '@/utils/format-integer.util';
import * as React from 'react';
import './index.scss';

export interface PartyProps {
  candidates: Candidate[];
  selectedCandidate: Candidate;
  onCandidateClick: () => void;
  gradientTable: GradientRow[];
  dataLevel: DataLevel;
}

function dataLevelToString(dataLevel: DataLevel) {
  switch (dataLevel) {
    case DataLevel.COMUNE:
      return 'Comune';
    case DataLevel.PROVINCIA:
      return 'Provincia';
    case DataLevel.REGIONE:
      return 'Regione';
    case DataLevel.MAIN:
    case DataLevel.CIRCOSCRIZIONE:
      return 'Circoscrizione';
    default:
      return '';
  }
}

export const Party: React.FC<PartyProps> = ({
  candidates,
  selectedCandidate,
  onCandidateClick,
  gradientTable,
  dataLevel,
}) => (
  <div className="Party">
    <div className="Party__bar">
      <div>
        <span>0</span>
      </div>
      {gradientTable.map((row, i) => (
        <div key={i.toString()}>
          <div
            style={{
              backgroundColor: selectedCandidate.fillColor,
              opacity: row.opacity,
            }}
          ></div>
          <span>{`${formatInteger(row.value, true)}`}</span>
        </div>
      ))}
    </div>
    <CoalitionsHeader />
    <Coalition
      {...selectedCandidate}
      showHeatMap
      isCandidateOpen
      onCandidateClick={onCandidateClick}
    />
    <div className="Party__regions">
      <div className="Party__region Party__header">
        <span>{dataLevelToString(dataLevel)}</span>
        <span>Voti</span>
        <span>%</span>
      </div>
      {candidates.map((candidate, i) => (
        <div className="Party__region" key={i.toString()}>
          <span>{candidate.label}</span>
          <span>{candidate.votes}</span>
          <span>{`${formatDecimal(candidate.percentage, true)}`}</span>
        </div>
      ))}
    </div>
  </div>
);
