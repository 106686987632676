export function formatInteger(value: number | string, percentage?: boolean) {
  if (value === null || value === undefined) return '';

  let n = typeof value === 'number' ? value : Number(value.replace(',', '.'));

  if (n === 0) {
    return '-';
  }
  return n.toFixed(0) + (percentage ? '%' : '');
}
