import Config from '@/lib/config';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from 'react';
import { default as RMap, MapProps } from 'react-map-gl';

export const Map: React.FC<MapProps> = ({ terrain, fog, ...props }) => (
  <>
    <RMap
      {...props}
      terrain={terrain || undefined}
      fog={fog || undefined}
      mapStyle={Config.mapboxStyle}
      mapboxAccessToken={Config.mapboxToken}
      locale={{
        'ScrollZoomBlocker.CtrlMessage':
          'Utilizza ctrl + scroll per ingrandire la mappa',
        'ScrollZoomBlocker.CmdMessage':
          'Utilizza ⌘ + scroll per ingrandire la mappa',
        'TouchPanBlocker.Message': 'Utilizza due dita per spostare la mappa',
      }}
      minZoom={1}
      maxZoom={15}
    />
  </>
);

//maxZoom={9.9}
