import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as React from 'react';
import './index.scss';

export interface SignProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
}

export const Sign: React.FC<SignProps> = ({ isOpen, ...props }) => (
  <div {...props} className="Sign">
    {isOpen ? <RemoveIcon /> : <AddIcon />}
  </div>
);
